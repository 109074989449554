import { PERSIST, TOKEN } from '../constants/ConfigConst';

export const baseIp = 'http://45.55.68.130:3000';
// export const baseIp = 'http://localhost:3000';
export const baseUrl = `${baseIp}/api/v1`;

export const token = ({ isFormData = false, isToken = true }) => {
  const token = localStorage.getItem(TOKEN);
  const headers = {
    'Content-Type': 'application/json',
  };
  if (isToken) headers['Authorization'] = `Bearer ${token}`;
  if (isFormData) headers['Content-Type'] = 'multipart/form-data';
  return headers;
};

export const isTokenValid = (res) => {
  if (res.status === 401) {
    localStorage.removeItem(TOKEN);
    localStorage.removeItem(PERSIST);
    setTimeout(() => {
      window.location.replace(`${baseIp}`);
    }, 1000);
  }
};
