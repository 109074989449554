import {
  ACTIVO_PEDIDOS,
  ALL_PEDIDOS,
  LOADING_ALL_PEDIDOS,
  SET_LOGOUT,
} from '../../store/Types';

const initialState = {
  all: [],
  loadingAll: false,
  meta: null,
  links: null,
  activo: null,
};

export const pedidosReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOGOUT:
      return {
        ...state,
        ...initialState,
      };
    case ALL_PEDIDOS:
      return {
        ...state,
        all: action.payload.data,
        links: action.payload.links,
        meta: action.payload.meta,
      };
    case ACTIVO_PEDIDOS:
      return {
        ...state,
        activo: action.payload,
      };
    case LOADING_ALL_PEDIDOS:
      return {
        ...state,
        loadingAll: action.payload,
      };
    default:
      return state;
  }
};
