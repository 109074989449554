import {
  aprobarSolicitudesService,
  deleteSolicitudesService,
  getAllSolicitudesService,
  getOneSolicitudesService,
  saveSolicitudesService,
} from '../../services';
import {
  ACTIVO_SOLICITUDES,
  ALL_SOLICITUDES,
  LOADING_ALL_SOLICITUDES,
} from '../../store/Types';
import { tryCatchHelper } from '../../utils';

export const getAllSolicitudesAction = async (dispatch) => {
  try {
    dispatch({ type: LOADING_ALL_SOLICITUDES, payload: true });
    const res = await getAllSolicitudesService(null);
    dispatch({ type: ALL_SOLICITUDES, payload: res.data });
    dispatch({ type: LOADING_ALL_SOLICITUDES, payload: false });
    return res.data;
  } catch (e) {
    dispatch({ type: LOADING_ALL_SOLICITUDES, payload: false });
    tryCatchHelper(e);
    return false;
  }
};

export const setActivoSolicitudesAction = async (dispatch, data) => {
  dispatch({ type: ACTIVO_SOLICITUDES, payload: data });
};

export const saveSolicitudesAction = async ({ dispatch, notify }, id, data) => {
  try {
    const res = await saveSolicitudesService(id || null, data);
    notify(res.data.message);
    if (id) setActivoSolicitudesAction(dispatch, res.data.data);
    await getAllSolicitudesAction(dispatch, null);
    return res.data;
  } catch (e) {
    tryCatchHelper({ e, notify });
    return false;
  }
};

export const getOneSolicitudesAction = async ({ dispatch, notify }, id) => {
  try {
    dispatch({ type: LOADING_ALL_SOLICITUDES, payload: true });
    const res = await getOneSolicitudesService(id);
    await setActivoSolicitudesAction(dispatch, res.data.data);
    dispatch({ type: LOADING_ALL_SOLICITUDES, payload: false });
    return res.data;
  } catch (e) {
    dispatch({ type: LOADING_ALL_SOLICITUDES, payload: false });
    tryCatchHelper({ e, notify });
    return false;
  }
};

export const deleteSolicitudesAction = async ({ dispatch, notify }, id) => {
  try {
    const res = await deleteSolicitudesService(id);
    notify(res.data.message);
    await setActivoSolicitudesAction(dispatch, null);
    await getAllSolicitudesAction(dispatch);
  } catch (e) {
    tryCatchHelper({ e, notify });
    return false;
  }
};

export const aprobarSolicitudesAction = async ({ dispatch, notify }, data) => {
  try {
    let res = await aprobarSolicitudesService(data);
    notify(res.data.message);
    await getAllSolicitudesAction(dispatch);
    return res.data;
  } catch (e) {
    tryCatchHelper({ e, notify });
    return false;
  }
};
