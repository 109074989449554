import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { ThemeProvider, createTheme } from '@mui/material';
import { red } from '@mui/material/colors';
import dayjs from 'dayjs';
import { SnackbarProvider } from 'notistack';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Router from './router/Router';
import { persistor, store } from './store/Store';

dayjs.locale('es');

const theme = createTheme({
  palette: {
    primary: {
      main: red[700],
    },
  },
});

const App = () => (
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Suspense fallback={<div>Loading</div>}>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            autoHideDuration={4000}
            onClose={() => {}}
          >
            <Router />
          </SnackbarProvider>
        </Suspense>
      </PersistGate>
    </Provider>
  </ThemeProvider>
);

ReactDOM.render(<App />, document.getElementById('root'));

// https://github.com/azizmashkour/react-i18n/blob/master/src/App.js
