import { Box, Grid, LinearProgress, Paper, Typography } from '@mui/material';
import * as React from 'react';
import * as Redux from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import * as ROUTES from '../../router/Routes';
import { getDashboardAction } from '../../store/Actions';
import BotonNuevoComponent from './components/BotonNuevo';

const DashboardView = () => {
  const dispatch = Redux.useDispatch();
  const history = useHistory();

  const dashboardStore = Redux.useSelector((state) => state.dashboard);

  const [loadingDashboard, setLoadingDashboard] = React.useState(false);

  React.useEffect(() => {
    setLoadingDashboard(true);
    getDashboardAction(dispatch);
    setLoadingDashboard(false);
  }, []);

  const Card = (route, data) => (
    <Grid item xs={12} md={3} lg={3}>
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {route.icon}
          <Typography variant="body" sx={{ fontWeight: '700' }}>
            {route.title}
          </Typography>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }}
        >
          <Typography variant="h4"> {data} </Typography>
          {route.to ? (
            <Link
              to={route.to}
              style={{
                color: 'black',
                textDecoration: 'none',
              }}
            >
              <BotonNuevoComponent
                click={() => {
                  history.push(route.to);
                }}
                morado
                text="Ver más"
              />
            </Link>
          ) : null}
        </Box>
      </Paper>
    </Grid>
  );

  return (
    <Grid container spacing={2}>
      <Grid item md={12}>
        {loadingDashboard && dashboardStore.dashboard === null ? (
          <Box sx={{ width: '100%' }}>
            <LinearProgress sx={{ mb: dashboardStore.dashboard > 0 ? 2 : 0 }} />
            <Typography variant="h6">Cargando estadísticas...</Typography>
          </Box>
        ) : null}
      </Grid>
      {Card(ROUTES.usuarios, dashboardStore.usuarios)}
      {Card(ROUTES.clientes, dashboardStore.clientes)}
      {Card(ROUTES.paseadores, dashboardStore.paseadores)}
      {Card(ROUTES.solicitudes, dashboardStore.solicitudes)}
    </Grid>
  );
};

export default DashboardView;
