import React from 'react';
import ContainerTemplate from '../../components/template/Container';
import * as ROUTES from '../../router/Routes';
import PedidosView from './PedidosView';

const PedidosModule = () => {
  return (
    <ContainerTemplate active={ROUTES.pedidos} routes={[]}>
      <PedidosView />
    </ContainerTemplate>
  );
};

export default PedidosModule;
