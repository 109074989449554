import MenuIcon from '@mui/icons-material/Menu';
import { Box, IconButton, Toolbar } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import * as Redux from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ROJO_COLOR } from '../../../constants/ColorsConst';
import { drawerWidth } from '../drawer/Drawer';
import ButtonProfile from './ButtonProfile';

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const AppBarTemplate = ({ open, toggleDrawer }) => {
  const loginStore = Redux.useSelector((state) => state.login);
  const dispatch = Redux.useDispatch();
  let history = useHistory();

  return (
    <AppBar position="fixed" open={open} sx={{ backgroundColor: ROJO_COLOR }}>
      <Toolbar
        sx={{
          pr: '24px', // keep right padding when drawer closed
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          sx={{
            marginRight: '36px',
            ...(open && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>
        {/* <strong>
                    {authStore.rol === SU && activo ? activo.attributes.name : null}
                </strong> */}
        {/* {
                    authStore.rol === SU && activo ?
                        (<IconButton
                            aria-label="delete"
                            size="large"
                            // sx={{ ml: 1 }}
                            onClick={() => {
                                setActivoEntidadesAction(dispatch, null)
                                history.push(ROUTES.entidades.to);
                            }}
                        >
                            <CloseIcon fontSize="inherit" sx={{ color: 'red' }} />
                        </IconButton>) : null
                } */}
        {/* <SearchAppbar /> */}
        <Box sx={{ flexGrow: 1 }} />
        {/* <Box sx={{ display: { xs: 'none', md: 'flex' } }}> */}
        {/* <IconButton size="large" aria-label="show 4 new mails" color="inherit" sx={{ mr: 0 }}>
                        <Badge badgeContent={4} color="error">
                            <MailIcon />
                        </Badge>
                    </IconButton> */}
        {/* <IconButton
                        size="large"
                        aria-label="show 17 new notifications"
                        color="inherit"
                        sx={{ mr: 1 }}
                    >
                        <Badge badgeContent={17} color="error">
                            <NotificationsIcon />
                        </Badge>
                    </IconButton> */}
        {/* </Box> */}
        <ButtonProfile />
      </Toolbar>
    </AppBar>
  );
};

export default AppBarTemplate;
