import { Grid } from '@mui/material';
import * as React from 'react';
import * as Redux from 'react-redux';
import ModalComponent from '../../components/modals/Modal';
import { getAllRazasAction, setActivoRazasAction } from '../../store/Actions';
import BotonNuevoComponent from '../dashboard/components/BotonNuevo';
import FormRazas from './components/FormRazas';
import ListadoRazas from './components/ListadoRazas';

const RazasView = () => {
  const dispatch = Redux.useDispatch();

  const razasStore = Redux.useSelector((state) => state.razas);

  const [modalForm, setModalForm] = React.useState(false);
  const [isInfo, setIsInfo] = React.useState(false);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <BotonNuevoComponent
          click={() => {
            setActivoRazasAction(dispatch, null);
            setModalForm(true);
          }}
          verde
          mr={2}
        />
        <BotonNuevoComponent
          click={async () => {
            await setActivoRazasAction(dispatch, null);
            await getAllRazasAction(dispatch);
          }}
          white
          disabled={razasStore.loadingAll || razasStore.all.length === 0}
          text={razasStore.loadingAll ? 'Actualizando...' : 'Actualizar'}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <ListadoRazas setModalForm={setModalForm} setIsInfo={setIsInfo} />
      </Grid>
      <ModalComponent
        id="modalFormRazas"
        title={
          !razasStore.activo
            ? 'Nueva raza'
            : `Raza: ${razasStore.activo?.nombre}`
        }
        open={modalForm}
        maxWidth="sm"
        onClose={() => setModalForm(!modalForm)}
      >
        {razasStore.activo ? (
          <FormRazas
            activo={razasStore.activo}
            setModalForm={setModalForm}
            setIsInfo={setIsInfo}
          />
        ) : (
          <FormRazas setModalForm={setModalForm} setIsInfo={setIsInfo} />
        )}
      </ModalComponent>
    </Grid>
  );
};

export default RazasView;
