import { Avatar, Grid, Stack, Typography } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import * as Redux from 'react-redux';
import { DeleteModalComponent, LoadingWidget } from '../../../components';
import {
  deleteSolicitudesAction,
  getAllSolicitudesAction,
  getOneSolicitudesAction,
  setActivoSolicitudesAction,
} from '../../../store/Actions';
import { NameComponent, textLabels, urlImage } from '../../../utils';
import { useNotification } from '../../../utils/Notification';
import BotonNuevoComponent from '../../dashboard/components/BotonNuevo';
import { ID } from '../../usuarios/UsuariosConst';
import ExpandeRowSolicitudes from './ExpandedRowSolicitudes';

const ListadoSolicitudes = ({ setModalForm }) => {
  const dispatch = Redux.useDispatch();
  const { notify } = useNotification();

  const solicitudesStore = Redux.useSelector((state) => state.solicitudes);

  const [rows, setRows] = React.useState([]);
  const [isDelete, setIsDelete] = React.useState(false);
  const [loadingDelete, setLoadingDelete] = React.useState(false);

  React.useEffect(() => {
    if (solicitudesStore.all && solicitudesStore.all.length === 0) {
      getAllSolicitudesAction(dispatch);
    }
  }, []);

  React.useEffect(() => {
    setRows([]);
    solicitudesStore.all.forEach((data) => {
      setRows((old) => [
        ...old,
        {
          id: data.id,
          foto: { ...data.usuario.informacion.foto },
          dni: data.usuario.informacion.dni,
          fullname: `${data.usuario.informacion.pnombre} ${
            data.usuario.informacion.snombre ?? ''
          } ${data.usuario.informacion.papellido} ${
            data.usuario.informacion.sapellido ?? ''
          }`,
          estatus: { ...data.usuario.estatus },
        },
      ]);
    });
  }, [solicitudesStore.all]);

  function findData(id) {
    const data = solicitudesStore.all.find((el) => el.id === id);
    setActivoSolicitudesAction(dispatch, data);
    return data;
  }

  const _onInfo = async (id) => {
    const data = findData(id);
    const res = await getOneSolicitudesAction(
      { dispatch, notify },
      data.usuario.id
    );
    if (res) setModalForm(true);
  };

  const _onDelete = async () => {
    setLoadingDelete(true);
    await deleteSolicitudesAction(
      { dispatch, notify },
      solicitudesStore.activo.id
    );
    setIsDelete(false);
    setLoadingDelete(false);
  };

  const columns = [
    {
      name: ID,
      options: {
        display: 'excluded',
      },
    },

    {
      name: 'foto',
      label: 'Foto',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Avatar
              alt={value.nombre ?? 'myPhoto'}
              sx={{ width: 30, height: 30 }}
              src={urlImage(value)}
            />
          );
        },
      },
    },
    {
      name: 'dni',
      label: 'DNI',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'fullname',
      label: 'Nombre y apellido',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NameComponent onClick={() => _onInfo(tableMeta.rowData[0])}>
              {' '}
              {value}{' '}
            </NameComponent>
          );
        },
      },
    },
    {
      name: 'estatus',
      label: 'Estatus',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography variant="body1" color="initial" fontWeight="bold">
              {value.nombre}
            </Typography>
          );
        },
      },
    },
    {
      name: '',
      label: '',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Stack direction="row" spacing={2} sx={{ width: '200px' }}>
              {/* <BotonNuevoComponent
                click={() => {
                  findData(tableMeta.rowData[0]);
                  setModalForm(true);
                }}
                text="Editar"
                azul
              /> */}
              <BotonNuevoComponent
                text="Eliminar"
                rojo
                click={() => {
                  findData(tableMeta.rowData[0]);
                  setIsDelete(true);
                }}
              />
            </Stack>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    responsive: 'scroll',
    setTableProps: () => {
      return {
        padding: 'none',
        size: 'small',
      };
    },
    print: false,
    download: false,
    textLabels,
    rowsPerPageOptions: [50, 100, 150],
    selectableRowsHideCheckboxes: true,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      return (
        <ExpandeRowSolicitudes
          data={solicitudesStore.all.find((el) => el.id === rowData[0])}
          length={rowData.length}
        />
      );
    },
  };

  return (
    <Grid container>
      <Grid item md={12}>
        <LoadingWidget
          isLoading={solicitudesStore.loadingAll || loadingDelete}
        />
        <MUIDataTable
          title={'Listado'}
          data={rows}
          columns={columns}
          options={options}
        />
      </Grid>
      {solicitudesStore.activo && (
        <DeleteModalComponent
          open={isDelete}
          onClose={() => setIsDelete(!isDelete)}
          onDelete={_onDelete}
        >
          <Typography variant="h6">{solicitudesStore.activo.nombre}</Typography>
        </DeleteModalComponent>
      )}
    </Grid>
  );
};

export default ListadoSolicitudes;
