export const ACTIONS = "actions";

// !======================================================
// ! VERIFICAR Y BORRAR
// !======================================================
export const DESCRIPCION = "descripcion";
export const NOMBRES = "nombres";
export const IMG_FOTO = "imgFoto";
export const TIPO_SANGRE = "tipoSangre";
export const TIENE_INTERNET = "tieneInternet";
export const ESTADO = "estado";
export const DIRECCION = "direccion";
export const OPERADORA = "operadora";
export const NUMERO = "numero";
export const PERFIL = "perfil";
export const ENTIDAD = "entidad";
export const ENTIDAD_PERFIL = "entidadPerfil";

export const NAME = "name";
export const ABBREVIATION = "abbreviation";
export const ADDRESS = "address";
export const LAT = "lat";
export const LNG = "lng";
export const PHONE = "phone";
export const RIF = "rif";
export const ENTITY_TYPE_ID = "entity_type_id";
export const STATUS_ID = "status_id";
export const IMAGE_TYPE = "image_type";
export const PROFILE_TYPE = "profile_type";

export const LOGO_WHITE = "logo_white";
export const LOGO_BLACK = "logo_black";
export const HOME = "home";
export const IMAGE = "image";

export const DISPONIBLE = "disponible";

export const USER_ID = "user_id";
export const PASSWORD_CONFIRMATION = "password_confirmation";
export const PROFILE = "profile";
export const IDENTIFICATION_NUMBER = "identification_number";
export const FIRST_NAME = "first_name";
export const MIDDLE_NAME = "middle_name";
export const FIRST_LAST_NAME = "first_last_name";
export const SECOND_LAST_NAME = "second_last_name";
export const BIRTHDAY = "birthday";
export const GENDER = "gender";
export const DOCUMENT_TYPE_ID = "document_type_id";
export const USER_TYPE = "user_type";
export const ENTITY_ID = "entity_id";

export const AVATAR = "avatar";

export const DATE_FROM = "date_from";
export const DATE_TO = "date_to";

// Areas
export const OPENING_TIME = "opening_time";
export const CLOSING_TIME = "closing_time";
export const DAYS_MAX_RESERVAS = "days_max_reserves";
export const MAX_RESERVAS = "max_reserves";
export const LIMIT_RESERVATIONS = "limit_reservations";
export const ACTIVE_RESERVES = "active_reserves";
export const DAYS_PREV_FOR_RESERVE = "days_prev_for_reserve";
export const BLOCK_RESERVATION = "block_reservation";
export const CONTINUOS_BLOCK = "continuous_block";
export const STATUS = "status";
export const AREA_CATEGORY_ID = "area_category_id";

export const AREA_ID = "area_id";
export const REASON_BLOCKED = "reason_blocked";
export const SUB_AREA_ID = "sub_area_id";
export const ENTRY_TIME = "entry_time";
export const DEPARTURE_TIME = "departure_time";
export const ENTRY_TIME_NUM = "entry_time_num";
export const DEPARTURE_TIME_NUM = "departure_time_num";

export const CATEGORY = "category";
export const AREA_CATEGORY = "area_category";

export const MAX_PERSONS = "max_persons";
export const MIN_PERSONS = "min_persons";
export const MAX_PERSONS_SHARED = "max_persons_shared";
export const INTERVAL = "interval";
export const MINUTES_USE = "minutes_use";
export const PRICE = "price";
export const DAYS_FOR_RESERVE = "days_for_reserve";
export const HOURS_FOR_RESERVE = "hours_for_reserve";
export const TIME_PREV_FOR_RESERVE = "time_prev_for_reserve";
export const IMAGE_NAME = "image_name";
export const IMAGE_URL = "image_url";

// Modulos
export const ACTIVE = "active";
export const HIDDEN = "hidden";
export const OWNER = "owner";
export const FAMILY = "family";
export const SERVICE = "service";

// Codigo
export const ARREARS = "arrears";
export const CODE_ID = "code_id";
export const CODE_TYPE = "code_type";
export const PLACE_ID = "place_id";

export const PAYMENT_DATE = "payment_date";
export const AMOUNT = "amount";
export const ACCOUNT = "account";
export const PAYMENT_STATUS = "payment_status";
export const CODE_IDS = "code_ids";

export const FILE = "file";
export const DATE = "date";
export const ENTiTY_DOCUMENT_TYPE = "entity_document_type";
export const DOCUMENT = "document";

export const RELAY_NUM = "relay_num";

/* Entidades */
export const IMG_LOGO = "imgLogo";
export const IMG_FONDO = "imgFondo";
export const IS_ABIERTO = "isAbierto";
export const TIPO_ENTIDAD = "tipoEntidad";
export const LATITUD = "latitud";
export const LONGITUD = "longitud";

export const FECHA = "fecha";
export const DESC = "desc";
export const TITULAR = "titular";
export const VISTAS = "vistas";
