import { GET_ALL_ESTATUS } from '../../store/Types'

const initialState = {
    all: [],
}

export const estatusReducer = (state = initialState, action) => {

    switch (action.type) {

        case GET_ALL_ESTATUS:
            return {
                ...state,
                all: action.payload.data,
            }
        default: return state
    }

}