import {
  ACTIVO_TARIFAS,
  ALL_TARIFAS,
  LOADING_ALL_TARIFAS,
} from '../../store/Types';
import { tryCatchHelper } from '../../utils';
import {
  default as TarifaService,
  default as TarifasService,
} from './TarifasService';

export const getAllTarifasAction = async (dispatch, params) => {
  try {
    dispatch({ type: LOADING_ALL_TARIFAS, payload: true });
    const res = await TarifasService.getAll(params);
    dispatch({ type: ALL_TARIFAS, payload: res.data });
    dispatch({ type: LOADING_ALL_TARIFAS, payload: false });
    return res.data;
  } catch (e) {
    dispatch({ type: LOADING_ALL_TARIFAS, payload: false });
    tryCatchHelper(e);
    return false;
  }
};

export const setActivoTarifasAction = async (dispatch, data) => {
  dispatch({ type: ACTIVO_TARIFAS, payload: data });
};

export const saveTarifasAction = async ({ dispatch, notify }, id, data) => {
  try {
    const res = await TarifasService.save(id || null, data);
    notify(res.data.message);
    if (id) setActivoTarifasAction(dispatch, res.data.data);
    await getAllTarifasAction(dispatch, null);
    return res.data;
  } catch (e) {
    tryCatchHelper({ e, notify });
    return false;
  }
};

export const getOneTarifasAction = async ({ dispatch, notify }, id) => {
  try {
    dispatch({ type: LOADING_ALL_TARIFAS, payload: true });
    const res = await TarifasService.getByOne(id);
    await setActivoTarifasAction(dispatch, res.data.data);
    dispatch({ type: LOADING_ALL_TARIFAS, payload: false });
    return res.data;
  } catch (e) {
    dispatch({ type: LOADING_ALL_TARIFAS, payload: false });
    tryCatchHelper({ e, notify });
    return false;
  }
};

export const deleteTarifasAction = async ({ dispatch, notify }, id) => {
  try {
    const res = await TarifaService.delete(id);
    notify(res.data.message);
    await setActivoTarifasAction(dispatch, null);
    await getAllTarifasAction(dispatch);
  } catch (e) {
    tryCatchHelper({ e, notify });
    return false;
  }
};
