import React from 'react';
import ContainerTemplate from '../../components/template/Container';
import * as ROUTES from '../../router/Routes';
import PaseadoresView from './PaseadoresView';

const PaseadoresModule = () => {
  return (
    <ContainerTemplate active={ROUTES.paseadores} routes={[]}>
      <PaseadoresView />
    </ContainerTemplate>
  );
};

export default PaseadoresModule;
