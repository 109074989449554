import { SET_LOGOUT, GET_DASHBOARD } from "../../store/Types";

const initialState = {};

export const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD:
      return {
        ...state,
        ...action.payload.data,
      };
    case SET_LOGOUT:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
