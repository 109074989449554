import {
  aprobarPaseadoresService,
  deletePaseadoresService,
  getAllPaseadoresService,
  getOnePaseadoresService,
  savePaseadoresService,
} from '../../services';
import {
  ACTIVO_PASEADORES,
  ALL_PASEADORES,
  LOADING_ALL_PASEADORES,
} from '../../store/Types';
import { tryCatchHelper } from '../../utils';

export const getAllPaseadoresAction = async (dispatch) => {
  try {
    dispatch({ type: LOADING_ALL_PASEADORES, payload: true });
    const res = await getAllPaseadoresService(null);
    dispatch({ type: ALL_PASEADORES, payload: res.data });
    dispatch({ type: LOADING_ALL_PASEADORES, payload: false });
    return res.data;
  } catch (e) {
    dispatch({ type: LOADING_ALL_PASEADORES, payload: false });
    tryCatchHelper(e);
    return false;
  }
};

export const setActivoPaseadoresAction = async (dispatch, data) => {
  dispatch({ type: ACTIVO_PASEADORES, payload: data });
};

export const savePaseadoresAction = async ({ dispatch, notify }, id, data) => {
  try {
    const res = await savePaseadoresService(id || null, data);
    notify(res.data.message);
    if (id) setActivoPaseadoresAction(dispatch, res.data.data);
    await getAllPaseadoresAction(dispatch, null);
    return res.data;
  } catch (e) {
    tryCatchHelper({ e, notify });
    return false;
  }
};

export const getOnePaseadoresAction = async ({ dispatch, notify }, id) => {
  try {
    dispatch({ type: LOADING_ALL_PASEADORES, payload: true });
    const res = await getOnePaseadoresService(id);
    await setActivoPaseadoresAction(dispatch, res.data.data);
    dispatch({ type: LOADING_ALL_PASEADORES, payload: false });
    return res.data;
  } catch (e) {
    dispatch({ type: LOADING_ALL_PASEADORES, payload: false });
    tryCatchHelper({ e, notify });
    return false;
  }
};

export const deletePaseadoresAction = async ({ dispatch, notify }, id) => {
  try {
    const res = await deletePaseadoresService(id);
    notify(res.data.message);
    await setActivoPaseadoresAction(dispatch, null);
    await getAllPaseadoresAction(dispatch);
  } catch (e) {
    tryCatchHelper({ e, notify });
    return false;
  }
};

export const aprobarPaseadoresAction = async ({ dispatch, notify }, data) => {
  try {
    let res = await aprobarPaseadoresService(data);
    notify(res.data.message);
    await getAllPaseadoresAction(dispatch);
    return res.data;
  } catch (e) {
    tryCatchHelper({ e, notify });
    return false;
  }
};
