import {
  aprobarClientesService,
  deleteClientesService,
  getAllClientesService,
  getOneClientesService,
  saveClientesService,
} from '../../services';
import {
  ACTIVO_CLIENTES,
  ALL_CLIENTES,
  LOADING_ALL_CLIENTES,
} from '../../store/Types';
import { tryCatchHelper } from '../../utils';

export const getAllClientesAction = async (dispatch) => {
  try {
    dispatch({ type: LOADING_ALL_CLIENTES, payload: true });
    const res = await getAllClientesService(null);
    dispatch({ type: ALL_CLIENTES, payload: res.data });
    dispatch({ type: LOADING_ALL_CLIENTES, payload: false });
    return res.data;
  } catch (e) {
    dispatch({ type: LOADING_ALL_CLIENTES, payload: false });
    tryCatchHelper(e);
    return false;
  }
};

export const setActivoClientesAction = async (dispatch, data) => {
  dispatch({ type: ACTIVO_CLIENTES, payload: data });
};

export const saveClientesAction = async ({ dispatch, notify }, id, data) => {
  try {
    const res = await saveClientesService(id || null, data);
    notify(res.data.message);
    if (id) setActivoClientesAction(dispatch, res.data.data);
    await getAllClientesAction(dispatch, null);
    return res.data;
  } catch (e) {
    tryCatchHelper({ e, notify });
    return false;
  }
};

export const getOneClientesAction = async ({ dispatch, notify }, id) => {
  try {
    dispatch({ type: LOADING_ALL_CLIENTES, payload: true });
    const res = await getOneClientesService(id);
    await setActivoClientesAction(dispatch, res.data.data);
    dispatch({ type: LOADING_ALL_CLIENTES, payload: false });
    return res.data;
  } catch (e) {
    dispatch({ type: LOADING_ALL_CLIENTES, payload: false });
    tryCatchHelper({ e, notify });
    return false;
  }
};

export const deleteClientesAction = async ({ dispatch, notify }, id) => {
  try {
    const res = await deleteClientesService(id);
    notify(res.data.message);
    await setActivoClientesAction(dispatch, null);
    await getAllClientesAction(dispatch);
  } catch (e) {
    tryCatchHelper({ e, notify });
    return false;
  }
};

export const aprobarClientesAction = async ({ dispatch, notify }, data) => {
  try {
    let res = await aprobarClientesService(data);
    notify(res.data.message);
    await getAllClientesAction(dispatch);
    return res.data;
  } catch (e) {
    tryCatchHelper({ e, notify });
    return false;
  }
};
