import { Avatar, Box, Grid, Stack, Tooltip, Typography } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import * as Redux from 'react-redux';
import { DeleteModalComponent, LoadingWidget } from '../../../components';
import {
  deletePedidosAction,
  getAllPedidosAction,
  getOnePedidosAction,
  setActivoPedidosAction,
} from '../../../store/Actions';
import { NameComponent, textLabels, urlImage } from '../../../utils';
import { useNotification } from '../../../utils/Notification';
import BotonNuevoComponent from '../../dashboard/components/BotonNuevo';
import { ID } from '../../usuarios/UsuariosConst';
import ExpandeRowPedidos from './ExpandedRowPedidos';

const ListadoPedidos = ({ setModalForm }) => {
  const dispatch = Redux.useDispatch();
  const { notify } = useNotification();

  const pedidosStore = Redux.useSelector((state) => state.pedidos);

  const [rows, setRows] = React.useState([]);
  const [isDelete, setIsDelete] = React.useState(false);
  const [loadingDelete, setLoadingDelete] = React.useState(false);

  React.useEffect(() => {
    if (pedidosStore.all && pedidosStore.all.length === 0) {
      getAllPedidosAction(dispatch);
    }
  }, []);

  React.useEffect(() => {
    setRows([]);
    pedidosStore.all.forEach((el) => {
      setRows((old) => [
        ...old,
        {
          ...el,
          servicio: {
            servicio: el.servicio,
            movimiento: el.movimiento,
          },
        },
      ]);
    });
  }, [pedidosStore.all]);

  function findData(id) {
    const data = pedidosStore.all.find((el) => el.id === id);
    setActivoPedidosAction(dispatch, data);
    return data;
  }

  const _onInfo = async (id) => {
    const res = findData(id);
    await getOnePedidosAction({ dispatch, notify }, res.id);
    setModalForm(true);
  };

  const _onDelete = async () => {
    setLoadingDelete(true);
    await deletePedidosAction({ dispatch, notify }, pedidosStore.activo.id);
    setIsDelete(false);
    setLoadingDelete(false);
  };

  const columns = [
    {
      name: ID,
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'servicio',
      label: 'Servicio',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Stack>
              <NameComponent onClick={() => _onInfo(tableMeta.rowData[0])}>
                {value.servicio.nombre}
              </NameComponent>
              <Typography variant="body1" color="initial">
                <strong>Método de pago: </strong>
                {value.movimiento.tipoPago}
              </Typography>
              <Typography variant="body1" color="initial">
                <strong>Paseo: </strong>
                {value.movimiento.paseo.nombre}(
                {value.movimiento.paseo.modalidad})
              </Typography>
            </Stack>
          );
        },
      },
    },
    {
      name: 'cliente',
      label: 'Cliente',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const fullname = `${value.informacion.pnombre} ${value.informacion.papellido}`;
          return (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
            >
              <Avatar
                alt={value.informacion.foto ?? 'myPhotoClient'}
                sx={{ width: 50, height: 50 }}
                src={urlImage(value.informacion.foto)}
              />
              <Box sx={{ marginLeft: 1 }}>
                <Typography variant="body1" color="initial">
                  <strong>{fullname}</strong>
                </Typography>
                <Typography variant="body1" color="initial">
                  <strong>Correo: </strong> {value.correo}
                </Typography>
                <Typography variant="body1" color="initial">
                  <strong>Teléfono: </strong> {value.informacion.telefono}
                </Typography>
              </Box>
            </Stack>
          );
        },
      },
    },
    {
      name: 'mascotas',
      label: 'Mascotas',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip describeChild title="Numero de mascotas" placement="left">
              <Typography>{value.length}</Typography>
            </Tooltip>
          );
        },
      },
    },
    {
      name: 'estado',
      label: 'Estado',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip describeChild title="Estatus del usuario" placement="left">
              <Typography>{value}</Typography>
            </Tooltip>
          );
        },
      },
    },
    {
      name: '',
      label: '',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Stack direction="row" spacing={2} sx={{ width: '200px' }}>
              <BotonNuevoComponent
                text="Eliminar"
                rojo
                click={() => {
                  findData(tableMeta.rowData[0]);
                  setIsDelete(true);
                }}
              />
            </Stack>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    responsive: 'scroll',
    setTableProps: () => {
      return {
        padding: 'none',
        size: 'small',
      };
    },
    print: false,
    download: false,
    textLabels,
    rowsPerPageOptions: [50, 100, 150],
    selectableRowsHideCheckboxes: true,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      return (
        <ExpandeRowPedidos
          data={pedidosStore.all.find((el) => el.id === rowData[0])}
          length={rowData.length}
        />
      );
    },
  };

  return (
    <Grid container>
      <Grid item md={12}>
        <LoadingWidget isLoading={pedidosStore.loadingAll || loadingDelete} />
        <MUIDataTable
          title={'Listado'}
          data={rows}
          columns={columns}
          options={options}
        />
      </Grid>
      {pedidosStore.activo && (
        <DeleteModalComponent
          open={isDelete}
          onClose={() => setIsDelete(!isDelete)}
          onDelete={_onDelete}
        >
          <Typography variant="h6">
            Servicio: {pedidosStore.activo.servicio.nombre}
          </Typography>
          <Typography variant="h6">
            Cliente: {pedidosStore.activo.cliente.informacion.dni}{' '}
            {pedidosStore.activo.cliente.informacion.pnombre}{' '}
            {pedidosStore.activo.cliente.informacion.papellido}
          </Typography>
        </DeleteModalComponent>
      )}
    </Grid>
  );
};

export default ListadoPedidos;
