import { Box, Grid, LinearProgress, Typography } from '@mui/material';
import * as React from 'react';
import * as Redux from 'react-redux';
import { Modal } from '../../components';
import { getAllUsuariosAction } from '../../store/Actions';
import {
  ALL_USUARIOS,
  GET_ONPASS_USUARIOS,
  GET_USUARIOS,
} from '../../store/Types';
import BotonNuevoComponent from '../dashboard/components/BotonNuevo';
import FormUsuarios from './components/FormUsuarios';
import ListadoUsuarios from './components/ListadoUsuarios';
// import BotonNuevoComponent from '../entidades/components/BotonNuevo';

const UsuariosModule = ({ all }) => {
  const dispatch = Redux.useDispatch();
  // const entidadesStore = Redux.useSelector(state => state.entidades)
  const usuariosStore = Redux.useSelector((state) => state.usuarios);

  const [loadingAll, setLoadingGetAll] = React.useState(false);
  const _handleGetAll = async () => {
    setLoadingGetAll(true);
    const isall = all ? true : false;
    await dispatch({
      type: GET_ONPASS_USUARIOS,
      payload: isall,
    });
    if (isall) {
      await dispatch({
        type: ALL_USUARIOS,
        payload: {
          data: [],
        },
      });
    }
    setTimeout(async () => {
      await getAllUsuariosAction(dispatch);
      setLoadingGetAll(false);
    }, 500);
  };

  React.useEffect(() => {
    if ((usuariosStore.all && usuariosStore.all.length === 0) || all)
      _handleGetAll();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [openModalForm, setOpenModalForm] = React.useState(false);

  return (
    <Grid container>
      <Grid container sx={{ mb: 2 }}>
        {usuariosStore.isAllDogout ? null : (
          <BotonNuevoComponent
            click={async () => {
              dispatch({
                type: GET_USUARIOS,
                payload: null,
              });
              // await setActivoUsuariosAction(dispatch, null)
              setOpenModalForm(true);
            }}
            verde
            mr={2}
          />
        )}
        {usuariosStore.all.length > 0 ? (
          <BotonNuevoComponent
            click={async () => {
              // await setActivoUsuariosAction(dispatch, null)
              _handleGetAll();
            }}
            white
            text={loadingAll ? 'Actualizando...' : 'Actualizar'}
          />
        ) : null}
      </Grid>
      {loadingAll ? (
        <Box sx={{ width: '100%' }}>
          <LinearProgress sx={{ mb: usuariosStore.all.length > 0 ? 2 : 0 }} />
          {usuariosStore.all.length === 0 ? (
            <Typography variant="h6">Cargando lista de usuarios...</Typography>
          ) : null}
        </Box>
      ) : null}
      {
        <ListadoUsuarios
          setOpenModalForm={(val) => setOpenModalForm(val)}
          all={all}
        />
      }
      <Modal
        id="modalFormUsuarios"
        title={`${
          usuariosStore.userNumber
            ? `Usuario encontrado: ${usuariosStore.userNumber.profile.first_name} ${usuariosStore.userNumber.profile.first_last_name}`
            : usuariosStore.activo
            ? 'Editar usuario'
            : 'Nuevo usuario'
        }`}
        open={openModalForm}
        onClose={() => setOpenModalForm(!openModalForm)}
      >
        {usuariosStore.activo ? (
          <Typography variant="h6">
            {usuariosStore.activo.nombres} {usuariosStore.activo.apellidos}
          </Typography>
        ) : null}
        <FormUsuarios
          activo={usuariosStore.activo}
          setOpenModalForm={(val) => setOpenModalForm(val)}
        />
      </Modal>
    </Grid>
  );
};

export default UsuariosModule;
