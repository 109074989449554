import axios from 'axios';
import { baseUrl, token } from '../../services/Apis';

export const pedidosApi = `${baseUrl}/pedidos`;

export const getAllPedidosService = async (params) => {
  return await axios.get(pedidosApi, {
    headers: await token({}),
    params,
  });
};

export const savePedidosService = async (id, data) => {
  if (id) {
    return await axios.patch(`${pedidosApi}/${id}`, data, {
      headers: await token({}),
    });
  }

  return await axios.post(pedidosApi, data, {
    headers: await token({}),
  });
};

export const getOnePedidosService = async (id) => {
  return await axios.get(`${pedidosApi}/${id}`, {
    headers: await token({}),
  });
};

export const deletePedidosService = async (id, idEnt) => {
  return await axios.delete(`${pedidosApi}/${id}`, {
    headers: await token({}),
  });
};
