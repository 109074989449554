import { getAllUsuariosService } from '../../services';
import { ALL_USUARIOS, LOADING_ALL_USUARIOS } from '../../store/Types';
import { tryCatchHelper } from '../../utils';

export const getAllUsuariosAction = async (dispatch) => {
  try {
    dispatch({ type: LOADING_ALL_USUARIOS, payload: true });
    const res = await getAllUsuariosService(null);
    dispatch({ type: ALL_USUARIOS, payload: res.data });
    dispatch({ type: LOADING_ALL_USUARIOS, payload: false });
    return res.data;
  } catch (e) {
    dispatch({ type: LOADING_ALL_USUARIOS, payload: false });
    tryCatchHelper(e);
    return false;
  }
};
