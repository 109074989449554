import axios from 'axios';
import { baseUrl, token } from '../../services/Apis';
import { usuariosApi } from '../usuarios/UsuariosService';

export const solicitudesApi = `${baseUrl}/paseadores`;

export const getAllSolicitudesService = async (params) => {
  const res = await axios.get(solicitudesApi, {
    headers: await token({}),
    params: {
      idEstatus: 5,
      ...params,
    },
  });
  return res;
};

export const saveSolicitudesService = async (id, data) => {
  if (id) {
    return await axios.patch(`${solicitudesApi}/${id}`, data, {
      headers: await token({}),
    });
  }
  return await axios.post(solicitudesApi, data, {
    headers: await token({}),
  });
};

export const getOneSolicitudesService = async (id) => {
  const res = await axios.get(`${solicitudesApi}/${id}`, {
    headers: await token({}),
  });
  return res;
};

export const deleteSolicitudesService = async (id, idEnt) => {
  const res = await axios.delete(`${solicitudesApi}/${id}`, {
    headers: await token({}),
  });
  return res;
};

export const aprobarSolicitudesService = async (data) => {
  return await axios.patch(`${usuariosApi}/estatus`, data, {
    headers: await token({
      isToken: true,
    }),
  });
};
