import * as React from 'react';
import * as Redux from "react-redux";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import AppBarTemplate from './appbar/Appbar';
import DrawerTemplate from './drawer/Drawer';
import { DOGOUT } from '../../constants/ConfigConst';
import ServerLocalComponent from './ServerLocal';

export const mdTheme = createTheme();

const Template = ({ children }) => {

    const entidadesStore = Redux.useSelector(state => state.entidades)

    const [open, setOpen] = React.useState(true);

    const openDrawer = 'openDrawer'
    const toggleDrawer = () => {
        setOpen(!open);
        localStorage.setItem(openDrawer, !open)
    };

    React.useEffect(() => {
        const od = localStorage.getItem(openDrawer)
        setOpen(od ? od === 'true' : true)
        // console.log(entidadesStore.activo)
    }, [])

    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBarTemplate
                    open={open}
                    toggleDrawer={toggleDrawer}
                />
                <DrawerTemplate
                    open={open}
                    toggleDrawer={toggleDrawer}
                />
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[200]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar />
                    <Container
                        maxWidth="xl"
                        sx={{
                            mt: 2,
                            mb: 2,
                        }}
                    >
                        {children}
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default Template;
