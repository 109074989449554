import {
  Avatar,
  AvatarGroup,
  Box,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import * as Redux from 'react-redux';
import {
  DeleteModalComponent,
  LoadingWidget,
  Modal,
} from '../../../components';
import {
  deleteClientesAction,
  getAllClientesAction,
  getOneClientesAction,
  setActivoClientesAction,
} from '../../../store/Actions';
import {
  NameComponent,
  dateformat,
  textLabels,
  urlImage,
} from '../../../utils';
import { useNotification } from '../../../utils/Notification';
import { ID } from '../../usuarios/UsuariosConst';
import ExpandeRowClientes from './ExpandedRowClientes';

const ListadoClientes = ({ setModalForm }) => {
  const dispatch = Redux.useDispatch();
  const { notify } = useNotification();

  const clientesStore = Redux.useSelector((state) => state.clientes);

  const [rows, setRows] = React.useState([]);
  const [isDelete, setIsDelete] = React.useState(false);
  const [loadingDelete, setLoadingDelete] = React.useState(false);
  const [mascotaActiva, setMascotaActiva] = React.useState(false);
  const [modalMascota, setModalMascota] = React.useState(false);

  React.useEffect(() => {
    if (clientesStore.all && clientesStore.all.length === 0) {
      getAllClientesAction(dispatch);
    }
  }, []);

  React.useEffect(() => {
    setRows([]);
    clientesStore.all.forEach((data) => {
      setRows((old) => [
        ...old,
        {
          ...data,
          id: data.id,
          foto: { ...data.informacion.foto },
          dni: data.informacion.dni,
          fullname: `${data.informacion.pnombre} ${
            data.informacion.snombre ?? ''
          } ${data.informacion.papellido} ${data.informacion.sapellido ?? ''}`,
          estatus: { ...data.estatus },
        },
      ]);
    });
  }, [clientesStore.all]);

  function findData(id) {
    const data = clientesStore.all.find((el) => el.id === id);
    setActivoClientesAction(dispatch, data);
    return data;
  }

  const _onInfo = async (id) => {
    const data = findData(id);
    const res = await getOneClientesAction({ dispatch, notify }, data.id);
    if (res) setModalForm(true);
  };

  const _onDelete = async () => {
    setLoadingDelete(true);
    await deleteClientesAction({ dispatch, notify }, clientesStore.activo.id);
    setIsDelete(false);
    setLoadingDelete(false);
  };

  const columns = [
    {
      name: ID,
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'foto',
      label: 'Foto',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Avatar
              alt={value.nombre ?? 'myPhoto'}
              sx={{ width: 30, height: 30 }}
              src={urlImage(value)}
            />
          );
        },
      },
    },
    {
      name: 'dni',
      label: 'Nor. Identificación',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'fullname',
      label: 'Nombre y apellido',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NameComponent onClick={() => _onInfo(tableMeta.rowData[0])}>
              {' '}
              {value}{' '}
            </NameComponent>
          );
        },
      },
    },
    {
      name: 'mascotas',
      label: 'Mascotas',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box sx={{ display: 'flex' }}>
              <AvatarGroup max={6} sx={{ paddingRight: 2 }}>
                {value.map((el) => {
                  return (
                    <Tooltip title={el.nombre} placement="top">
                      <Avatar
                        key={el.id}
                        alt={el.nombre}
                        src={urlImage(el.image)}
                        onClick={() => {
                          setMascotaActiva(el);
                          setModalMascota(true);
                        }}
                      />
                    </Tooltip>
                  );
                })}
              </AvatarGroup>
            </Box>
          );
        },
      },
    },
    {
      name: 'estatus',
      label: 'Estatus',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography variant="body1" color="initial" fontWeight="bold">
              {value.nombre}
            </Typography>
          );
        },
      },
    },
    // {
    //   name: '',
    //   label: '',
    //   options: {
    //     filter: true,
    //     sort: true,
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return (
    //         <Stack direction="row" spacing={2} sx={{ width: '200px' }}>
    //           {/* <BotonNuevoComponent
    //             click={() => {
    //               findData(tableMeta.rowData[0]);
    //               setModalForm(true);
    //             }}
    //             text="Editar"
    //             azul
    //           /> */}
    //           <BotonNuevoComponent
    //             text="Eliminar"
    //             rojo
    //             click={() => {
    //               findData(tableMeta.rowData[0]);
    //               setIsDelete(true);
    //             }}
    //           />
    //         </Stack>
    //       );
    //     },
    //   },
    // },
  ];

  const options = {
    filterType: 'dropdown',
    responsive: 'scroll',
    setTableProps: () => {
      return {
        padding: 'none',
        size: 'small',
      };
    },
    print: false,
    download: false,
    textLabels,
    rowsPerPageOptions: [50, 100, 150],
    selectableRowsHideCheckboxes: true,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      return (
        <ExpandeRowClientes
          data={clientesStore.all.find((el) => el.id === rowData[0])}
          length={rowData.length}
        />
      );
    },
  };

  return (
    <Grid container>
      <Grid item md={12}>
        <LoadingWidget isLoading={clientesStore.loadingAll || loadingDelete} />
        <MUIDataTable
          title={'Listado'}
          data={rows}
          columns={columns}
          options={options}
        />
      </Grid>
      {clientesStore.activo && (
        <DeleteModalComponent
          open={isDelete}
          onClose={() => setIsDelete(!isDelete)}
          onDelete={_onDelete}
        >
          <Typography variant="h6">
            {clientesStore.activo.informacion.pnombre}{' '}
            {clientesStore.activo.informacion.papellido}
          </Typography>
          <AvatarGroup max={6}>
            {clientesStore.activo.mascotas.map((el) => {
              return (
                <Tooltip title={el.nombre} placement="top">
                  <Avatar
                    key={el.id}
                    alt={el.nombre}
                    src={urlImage(el.image)}
                    onClick={() => {
                      setMascotaActiva(el);
                      setModalMascota(true);
                    }}
                  />
                </Tooltip>
              );
            })}
          </AvatarGroup>
        </DeleteModalComponent>
      )}
      <Modal
        id="imagenMascota"
        title={`Mascota: ${mascotaActiva.nombre}`}
        open={modalMascota}
        onClose={() => {
          setModalMascota(false);
          setMascotaActiva(false);
        }}
        maxWidth="sm"
        isCloseBackdrop
      >
        {mascotaActiva.image && (
          <Box sx={{ width: '100%' }} align="center">
            <img src={urlImage(mascotaActiva.image)} width="50%" />
          </Box>
        )}
        <Typography variant="body1" color="initial">
          <strong>Nombre: </strong>
          {mascotaActiva?.nombre}
        </Typography>
        <Typography variant="body1" color="initial">
          <strong>Fecha: </strong>
          {dateformat(mascotaActiva?.fecha, { format: 'DD-MM-YYYY' })}
        </Typography>
        <Typography variant="body1" color="initial">
          <strong>Raza: </strong>
          {mascotaActiva?.raza?.nombre}
        </Typography>
      </Modal>
    </Grid>
  );
};

export default ListadoClientes;
