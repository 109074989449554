import axios from "axios";
import { baseUrl, token } from "../../services/Apis";

export const dashboard = `${baseUrl}/dashboard`;

export const getDashboardService = async () => {
    let url = dashboard;

    try {
        const res = await axios.get(url, {
            headers: await token({ isToken: true }),
        });
        return res;
    } catch (e) {
        console.log(e);
    }
};
