import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DashboardCustomizeRoundedIcon from '@mui/icons-material/DashboardCustomizeRounded';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import PetsIcon from '@mui/icons-material/Pets';
import ReceiptIcon from '@mui/icons-material/Receipt';

/*========================================================
/ Authentication
/======================================================== */
export const login = {
  title: 'Inicio de sesion',
  icon: 'fa fa-user',
  to: '/login',
};
export const register = {
  title: 'Registro',
  icon: 'fa fa-user',
  to: '/register',
};
export const recover = {
  title: 'Recuperar Contraseña',
  icon: '',
  to: '/recoverPassword',
};
export const confirmEmail = {
  title: 'Confirmar Correo',
  icon: '',
  to: '/confirmEmail',
};

/*========================================================
/ Dashboard
/======================================================== */
export const dashboard = {
  title: 'Panel de Control',
  icon: <DashboardCustomizeRoundedIcon />,
  to: '/dashboard',
};
export const usuarios = {
  title: 'Usuarios',
  icon: <PeopleAltIcon />,
  to: '/usuarios',
};
export const clientes = {
  title: 'Clientes',
  icon: <PersonOutlineIcon />,
  to: '/clientes',
};
export const paseadores = {
  title: 'Paseadores',
  icon: <PersonPinCircleIcon />,
  to: '/paseadores',
};
export const solicitudes = {
  title: 'Solicitudes',
  icon: <PersonAddIcon />,
  to: '/solicitudes',
};
export const razas = {
  title: 'Razas',
  icon: <PetsIcon />,
  to: '/razas',
};
export const pedidos = {
  title: 'Pedidos',
  icon: <ReceiptIcon />,
  to: '/pedidos',
};
export const tarifas = {
  title: 'Tarifas',
  icon: <AttachMoneyIcon />,
  to: '/tarifas',
};
