import { combineReducers } from 'redux';
import { LoginReducer } from '../modules/auth/login/LoginReducer';
import { clientesReducer } from '../modules/clientes/ClientesReducer';
import { dashboardReducer } from '../modules/dashboard/DashboardReducer';
import { estatusReducer } from '../modules/estatus/EstatusReducer';
import { paseadoresReducer } from '../modules/paseadores/PaseadoresReducer';
import { paseosReducer } from '../modules/paseos/PaseosReducer';
import { pedidosReducer } from '../modules/pedidos/PedidosReducer';
import { razasReducer } from '../modules/razas/RazasReducer';
import { solicitudesReducer } from '../modules/solicitudes/SolicitudesReducer';
import { tarifasReducer } from '../modules/tarifas/TarifasReducer';
import { usuariosReducer } from '../modules/usuarios/UsuariosReducer';

const rootReducer = combineReducers({
  login: LoginReducer,
  estatus: estatusReducer,
  dashboard: dashboardReducer,
  usuarios: usuariosReducer,
  paseadores: paseadoresReducer,
  solicitudes: solicitudesReducer,
  razas: razasReducer,
  pedidos: pedidosReducer,
  clientes: clientesReducer,
  tarifas: tarifasReducer,
  paseos: paseosReducer,
});

export default rootReducer;
