import {
  deleteRazasService,
  getAllRazasService,
  getOneRazasService,
  saveRazasService,
} from '../../services';
import { ACTIVO_RAZAS, ALL_RAZAS, LOADING_ALL_RAZAS } from '../../store/Types';
import { tryCatchHelper } from '../../utils';

export const getAllRazasAction = async (dispatch) => {
  try {
    dispatch({ type: LOADING_ALL_RAZAS, payload: true });
    const res = await getAllRazasService(null);
    dispatch({ type: ALL_RAZAS, payload: res.data });
    dispatch({ type: LOADING_ALL_RAZAS, payload: false });
    return res.data;
  } catch (e) {
    dispatch({ type: LOADING_ALL_RAZAS, payload: false });
    tryCatchHelper(e);
    return false;
  }
};

export const setActivoRazasAction = async (dispatch, data) => {
  dispatch({ type: ACTIVO_RAZAS, payload: data });
};

export const saveRazasAction = async ({ dispatch, notify }, id, data) => {
  try {
    const res = await saveRazasService(id || null, data);
    notify(res.data.message);
    if (id) setActivoRazasAction(dispatch, res.data.data);
    await getAllRazasAction(dispatch, null);
    return res.data;
  } catch (e) {
    tryCatchHelper({ e, notify });
    return false;
  }
};

export const getOneRazasAction = async ({ dispatch, notify }, id) => {
  try {
    dispatch({ type: LOADING_ALL_RAZAS, payload: true });
    const res = await getOneRazasService(id);
    await setActivoRazasAction(dispatch, res.data.data);
    return res.data;
  } catch (e) {
    tryCatchHelper({ e, notify });
    return false;
  }
};

export const deleteRazasAction = async ({ dispatch, notify }, id) => {
  try {
    const res = await deleteRazasService(id);
    notify(res.data.message);
    await setActivoRazasAction(dispatch, null);
    await getAllRazasAction(dispatch);
  } catch (e) {
    tryCatchHelper({ e, notify });
    return false;
  }
};
