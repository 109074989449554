import axios from 'axios';
import { baseUrl, token } from '../../services/Apis';

export const razasApi = `${baseUrl}/razas`;

export const getAllRazasService = async (params) => {
  return await axios.get(razasApi, {
    headers: await token({}),
    params,
  });
};

export const saveRazasService = async (id, data) => {
  if (id) {
    return await axios.patch(`${razasApi}/${id}`, data, {
      headers: await token({}),
    });
  }

  return await axios.post(razasApi, data, {
    headers: await token({}),
  });
};

export const getOneRazasService = async (id) => {
  return await axios.get(`${razasApi}/${id}`, {
    headers: await token({}),
  });
};

export const deleteRazasService = async (id, idEnt) => {
  return await axios.delete(`${razasApi}/${id}`, {
    headers: await token({}),
  });
};
