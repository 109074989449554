import React from 'react';
import ContainerTemplate from '../../components/template/Container';
import * as ROUTES from '../../router/Routes';
import DashboardView from './DashboardView';

const DashboardModule = () => {
  return (
    <ContainerTemplate active={ROUTES.dashboard} routes={[]}>
      <DashboardView />
    </ContainerTemplate>
  );
};

export default DashboardModule;
