import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Slide } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 1 }} {...other}>
      {children}
      {onClose && (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 5,
            color: (theme) => theme.palette.grey,
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
    </DialogTitle>
  );
};

const ModalComponent = ({
  children,
  open,
  onClose,
  title,
  id,
  maxWidth,
  onCloseBackdrop,
  isCloseBackdrop,
}) => {
  return (
    <BootstrapDialog
      onClose={isCloseBackdrop ? onClose : onCloseBackdrop ?? null}
      aria-labelledby={id}
      open={open}
      fullWidth
      maxWidth={maxWidth ?? 'md'}
      TransitionComponent={Transition}
    >
      {title && (
        <BootstrapDialogTitle id={id} onClose={onClose}>
          {title}
        </BootstrapDialogTitle>
      )}
      <DialogContent dividers>{children}</DialogContent>
    </BootstrapDialog>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default ModalComponent;
