import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import { Menu, IconButton, MenuItem, Divider } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import SensorDoorIcon from '@mui/icons-material/SensorDoor';

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
))(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === "light"
                ? "rgb(55, 65, 81)"
                : theme.palette.grey[500],
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "4px 0",
        },
        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            "&:active": {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity
                ),
            },
        },
    },
}));

const ActionsList = ({
    id,
    onEdit,
    onSuscripcion,
    onAdministrador,
    onVistas,
    onDelete,
    onAsignarPuerta,
    onAsignarModulos,
    activo,
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const editComponent = onEdit ? (
        <MenuItem
            key={`${id}edit`}
            onClick={() => {
                onEdit();
                setAnchorEl(null);
            }}
            disableRipple
        >
            <EditIcon />
            Editar
        </MenuItem>
    ) : null;

    const suscripcionComponent = onSuscripcion ? (
        <MenuItem
            key={`${id}suscription`}
            onClick={() => {
                onSuscripcion();
                setAnchorEl(null);
            }}
            disableRipple
        >
            <WorkspacePremiumIcon />
            Suscripción
        </MenuItem>
    ) : null;

    const administradorComponent = onAdministrador ? (
        <MenuItem
            key={`${id}administrator`}
            onClick={() => {
                onAdministrador();
                setAnchorEl(null);
            }}
            disableRipple
        >
            <AssignmentIndIcon />
            Administrador
        </MenuItem>
    ) : null;

    const vistasComponent = onVistas ? (
        <MenuItem
            key={`${id}vistas`}
            onClick={() => {
                onVistas();
                setAnchorEl(null);
            }}
            disableRipple
        >
            <ViewModuleIcon />
            Vistas
        </MenuItem>
    ) : null;

    const deleteComponent = onDelete ? (
        <MenuItem
            key={`${id}delete`}
            onClick={() => {
                onDelete();
                setAnchorEl(null);
            }}
            disableRipple
        >
            <DeleteForeverIcon />
            Eliminar
        </MenuItem>
    ) : null;

    const asignarPuertaComponent = onAsignarPuerta ? (
        <MenuItem
            key={`${id}asignarPuerta`}
            onClick={() => {
                onAsignarPuerta();
                setAnchorEl(null);
            }}
            disableRipple
        >
            <SensorDoorIcon />
            Asignar Puerta
        </MenuItem>
    ) : null;

    const asignarModulosComponent = onAsignarModulos ? (
        <MenuItem
            key={`${id}asignarModulos`}
            onClick={() => {
                onAsignarModulos();
                setAnchorEl(null);
            }}
            disableRipple
        >
            <ViewModuleIcon />
            Asignar Módulos
        </MenuItem>
    ) : null;

    const menus = [
        editComponent,
        suscripcionComponent,
        administradorComponent,
        vistasComponent,
        deleteComponent,
        asignarPuertaComponent,
        asignarModulosComponent,
    ];

    return (
        <div>
            <IconButton
                aria-label="more"
                id={`${id}icon`}
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon sx={{ color: `${activo ? "white" : "balck"}` }} />
            </IconButton>
            <StyledMenu
                id={`${id}styled`}
                MenuListProps={{
                    "aria-labelledby": id,
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {menus.map((e) => e)}
            </StyledMenu>
        </div>
    );
};

export default ActionsList;
