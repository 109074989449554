import { Grid, Stack, Tooltip, Typography } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import * as Redux from 'react-redux';
import { DeleteModalComponent, LoadingWidget } from '../../../components';
import {
  deleteRazasAction,
  getAllRazasAction,
  getOneRazasAction,
  setActivoRazasAction,
} from '../../../store/Actions';
import { NameComponent, textLabels } from '../../../utils';
import { useNotification } from '../../../utils/Notification';
import BotonNuevoComponent from '../../dashboard/components/BotonNuevo';
import { ID } from '../../usuarios/UsuariosConst';
import ExpandeRowRazas from './ExpandedRowRazas';

const ListadoRazas = ({ setModalForm, setIsInfo }) => {
  const dispatch = Redux.useDispatch();
  const { notify } = useNotification();

  const razasStore = Redux.useSelector((state) => state.razas);

  const [isDelete, setIsDelete] = React.useState(false);
  const [loadingDelete, setLoadingDelete] = React.useState(false);

  React.useEffect(() => {
    if (razasStore.all && razasStore.all.length === 0) {
      getAllRazasAction(dispatch);
    }
  }, []);

  function findData(id) {
    const data = razasStore.all.find((el) => el.id === id);
    setActivoRazasAction(dispatch, data);
    return data;
  }

  const _onInfo = async (id) => {
    const res = findData(id);
    await getOneRazasAction({ dispatch, notify }, res.id);
    setIsInfo(true);
  };

  const _onDelete = async () => {
    setLoadingDelete(true);
    await deleteRazasAction({ dispatch, notify }, razasStore.activo.id);
    setIsDelete(false);
    setLoadingDelete(false);
  };

  const columns = [
    {
      name: ID,
      options: {
        display: 'excluded',
      },
    },
    // {
    //   name: 'image',
    //   label: 'Imagen',
    //   options: {
    //     filter: false,
    //     sort: false,
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return (
    //         <Avatar
    //           alt={value ?? 'myPhoto'}
    //           sx={{ width: 30, height: 30 }}
    //           src={urlImage(value)}
    //         />
    //       );
    //     },
    //   },
    // },
    {
      name: 'nombre',
      label: 'Nombre',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NameComponent onClick={() => _onInfo(tableMeta.rowData[0])}>
              {value}
            </NameComponent>
          );
        },
      },
    },

    {
      name: 'activo',
      label: 'Activo',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip describeChild title="Estatus del usuario" placement="left">
              <Typography>{value ? 'SI' : 'NO'}</Typography>
            </Tooltip>
          );
        },
      },
    },
    {
      name: '',
      label: '',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Stack direction="row" spacing={2} sx={{ width: '200px' }}>
              <BotonNuevoComponent
                click={() => {
                  findData(tableMeta.rowData[0]);
                  setModalForm(true);
                }}
                text="Editar"
                azul
              />
              <BotonNuevoComponent
                text="Eliminar"
                rojo
                click={() => {
                  findData(tableMeta.rowData[0]);
                  setIsDelete(true);
                }}
              />
            </Stack>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    responsive: 'scroll',
    setTableProps: () => {
      return {
        padding: 'none',
        size: 'small',
      };
    },
    print: false,
    download: false,
    textLabels,
    rowsPerPageOptions: [50, 100, 150],
    selectableRowsHideCheckboxes: true,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      return (
        <ExpandeRowRazas
          data={razasStore.all.find((el) => el.id === rowData[0])}
          length={rowData.length}
        />
      );
    },
  };

  return (
    <Grid container>
      <Grid item md={12}>
        <LoadingWidget isLoading={razasStore.loadingAll || loadingDelete} />
        <MUIDataTable
          title={'Listado'}
          data={razasStore.all}
          columns={columns}
          options={options}
        />
      </Grid>
      {razasStore.activo && (
        <DeleteModalComponent
          open={isDelete}
          onClose={() => setIsDelete(!isDelete)}
          onDelete={_onDelete}
        >
          <Typography variant="h6">{razasStore.activo.nombre}</Typography>
        </DeleteModalComponent>
      )}
    </Grid>
  );
};

export default ListadoRazas;
