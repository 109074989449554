import { Grid, TextField } from '@mui/material';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import * as Redux from 'react-redux';
import * as Yup from 'yup';
import { useNotification } from '../../../utils/Notification';
import BotonNuevoComponent from '../../dashboard/components/BotonNuevo';
import { saveRazasAction } from '../RazasAction';

const validationSchema = Yup.object({
  nombre: Yup.string().required('El nombre es requerido'),
  desc: Yup.string().required('La descripción es requerida'),
});

const FormRazas = ({ activo, setModalForm, setIsInfo }) => {
  const dispatch = Redux.useDispatch();
  const { notify } = useNotification();

  const handleSubmit = async (values, { setSubmitting }) => {
    await saveRazasAction({ dispatch, notify }, activo?.id, values);
    setSubmitting(false);
    setModalForm(false);
  };

  return (
    <Formik
      initialValues={{ nombre: activo?.nombre ?? '', desc: activo?.desc ?? '' }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <Field
                as={TextField}
                id="nombre"
                name="nombre"
                fullWidth
                size="small"
                required
                label="Nombre"
              />
              <ErrorMessage name="nombre" component="div" />
            </Grid>
            <Grid item xs={12} md={12}>
              <Field
                as={TextField}
                id="desc"
                name="desc"
                fullWidth
                size="small"
                required
                label="Descripción"
                multiline
                rows={4}
              />
              <ErrorMessage name="desc" component="div" />
            </Grid>

            <Grid item xs={12} md={12} align="center">
              <BotonNuevoComponent
                type="submit"
                text="GUARDAR"
                verde
                disabled={isSubmitting}
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default FormRazas;
