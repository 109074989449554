import { TableCell, TableRow } from '@mui/material';
import * as React from 'react';

const ExpandeRowPedidos = ({ data, length }) => {
  const [openModalForm, setOpenModalForm] = React.useState(false);

  return (
    <TableRow>
      <TableCell sx={{ padding: '15px' }} colSpan={length}></TableCell>
    </TableRow>
  );
};

ExpandeRowPedidos.propTypes = {};

export default ExpandeRowPedidos;
