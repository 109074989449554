import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import BotonComponent from '../../modules/dashboard/components/BotonNuevo';
import ModalComponent from './Modal';

const DeleteModalComponent = ({
  open = false,
  onClose = () => {},
  children,
  onDelete = () => {},
  isText = true,
  text = '¿Seguro que quieres eliminar este registro?',
  isConfirmBottom = true,
  title = 'Eliminar',
  isDelete = true,
}) => {
  return (
    <ModalComponent
      id="modal-delete-data"
      title={title}
      open={open}
      maxWidth="sm"
      onClose={onClose}
    >
      <Grid container>
        {isText && (
          <Grid item md={12}>
            <Typography variant="body1" sx={{ mb: 1 }}>
              {text}
            </Typography>
          </Grid>
        )}
        <Grid item md={12}>
          {children}
        </Grid>
        <Grid item md={12} align="right" sx={{ mt: 2 }}>
          {isConfirmBottom && (
            <BotonComponent
              click={onDelete}
              text="CONFIRMAR"
              deleteIcon={isDelete}
              rojo={isDelete}
              saveIcon={!isDelete}
              verde={!isDelete}
              mr={2}
            />
          )}
          <BotonComponent click={onClose} white text="CANCELAR" closeIcon />
        </Grid>
      </Grid>
    </ModalComponent>
  );
};

DeleteModalComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  delete: PropTypes.bool,
};

export default DeleteModalComponent;
