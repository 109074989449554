import React from 'react';
import ContainerTemplate from '../../components/template/Container';
import * as ROUTES from '../../router/Routes';
import TarifasView from './TarifasView';

const TarifasModule = () => {
  return (
    <ContainerTemplate active={ROUTES.tarifas} routes={[]}>
      <TarifasView />
    </ContainerTemplate>
  );
};

export default TarifasModule;
