import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {
  Box,
  Divider,
  IconButton,
  List,
  Toolbar,
  Typography,
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import * as Redux from 'react-redux';
import * as ROUTES from '../../../router/Routes';
import ItemDrawer from './ItemDrawer';

export const drawerWidth = 300;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

function generarClaveAleatoria() {
  const caracteres =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let clave = '';
  for (let i = 0; i < 16; i++) {
    clave += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
  }
  return clave;
}

const DrawerTemplate = ({ open, toggleDrawer }) => {
  const loginStore = Redux.useSelector((state) => state.login);

  const lista = (
    <List component="nav">
      <ItemDrawer
        key={generarClaveAleatoria()}
        item={ROUTES.dashboard}
        openDrawer={open}
      />
      <ItemDrawer
        key={generarClaveAleatoria()}
        item={ROUTES.usuarios}
        openDrawer={open}
      />
      <ItemDrawer
        key={generarClaveAleatoria()}
        item={ROUTES.paseadores}
        openDrawer={open}
      />
      <ItemDrawer
        key={generarClaveAleatoria()}
        item={ROUTES.solicitudes}
        openDrawer={open}
      />
      <ItemDrawer
        key={generarClaveAleatoria()}
        item={ROUTES.razas}
        openDrawer={open}
      />
      <ItemDrawer
        key={generarClaveAleatoria()}
        item={ROUTES.pedidos}
        openDrawer={open}
      />
      <ItemDrawer
        key={generarClaveAleatoria()}
        item={ROUTES.clientes}
        openDrawer={open}
      />
      <ItemDrawer
        key={generarClaveAleatoria()}
        item={ROUTES.tarifas}
        openDrawer={open}
      />
    </List>
  );

  return (
    <Drawer variant="permanent" open={open}>
      <Box>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            px: [1],
          }}
        >
          <Typography sx={{ fontWeight: 700 }} component="h1" variant="h4">
            DogOut
          </Typography>
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
      </Box>
      <Box sx={{ height: '100%' }}>{lista}</Box>
      <Box
        sx={{
          display: 'flex',
          height: '100px',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          mb: 3,
        }}
      >
        <img
          src="img/logo2.png"
          width={open ? 80 : 50}
          height={open ? 80 : 50}
        />
      </Box>
    </Drawer>
  );
};

export default DrawerTemplate;
