import React from 'react';
import ContainerTemplate from '../../components/template/Container';
import * as ROUTES from '../../router/Routes';
import UsuariosView from './UsuariosView';

const UsuariosPage = () => {
  return (
    <ContainerTemplate active={ROUTES.usuarios} routes={[]}>
      <UsuariosView />
    </ContainerTemplate>
  );
};

export default UsuariosPage;
