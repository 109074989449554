import { Box, LinearProgress, Typography } from '@mui/material';
import React from 'react';

const LoadingWidget = ({ isLoading, text, pb }) => {
  return (
    isLoading && (
      <Box sx={{ width: '100%', pb }}>
        {text && (
          <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
            {text}
          </Typography>
        )}
        <LinearProgress />
      </Box>
    )
  );
};

export default LoadingWidget;
