import {
  deletePaseosService,
  getAllPaseosService,
  getOnePaseosService,
  savePaseosService,
} from '../../services';
import {
  ACTIVO_PASEOS,
  ALL_PASEOS,
  LOADING_ALL_PASEOS,
} from '../../store/Types';
import { tryCatchHelper } from '../../utils';

export const getAllPaseosAction = async (dispatch, params) => {
  try {
    dispatch({ type: LOADING_ALL_PASEOS, payload: true });
    const res = await getAllPaseosService(params);
    dispatch({ type: ALL_PASEOS, payload: res.data });
    dispatch({ type: LOADING_ALL_PASEOS, payload: false });
    return res.data;
  } catch (e) {
    dispatch({ type: LOADING_ALL_PASEOS, payload: false });
    tryCatchHelper(e);
    return false;
  }
};

export const setActivoPaseosAction = async (dispatch, data) => {
  dispatch({ type: ACTIVO_PASEOS, payload: data });
};

export const savePaseosAction = async ({ dispatch, notify }, id, data) => {
  try {
    const res = await savePaseosService(id || null, data);
    notify(res.data.message);
    if (id) setActivoPaseosAction(dispatch, res.data.data);
    await getAllPaseosAction(dispatch, null);
    return res.data;
  } catch (e) {
    tryCatchHelper({ e, notify });
    return false;
  }
};

export const getOnePaseosAction = async ({ dispatch, notify }, id) => {
  try {
    dispatch({ type: LOADING_ALL_PASEOS, payload: true });
    const res = await getOnePaseosService(id);
    await setActivoPaseosAction(dispatch, res.data.data);
    return res.data;
  } catch (e) {
    tryCatchHelper({ e, notify });
    return false;
  }
};

export const deletePaseosAction = async ({ dispatch, notify }, id) => {
  try {
    const res = await deletePaseosService(id);
    notify(res.data.message);
    await setActivoPaseosAction(dispatch, null);
    await getAllPaseosAction(dispatch);
  } catch (e) {
    tryCatchHelper({ e, notify });
    return false;
  }
};
