import {
  ACTIVO_RAZAS,
  ALL_RAZAS,
  LOADING_ALL_RAZAS,
  SET_LOGOUT,
} from '../../store/Types';

const initialState = {
  all: [],
  loadingAll: false,
  meta: null,
  links: null,
  activo: null,
};

export const razasReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOGOUT:
      return {
        ...state,
        ...initialState,
      };
    case ALL_RAZAS:
      return {
        ...state,
        all: action.payload.data,
        links: action.payload.links,
        meta: action.payload.meta,
      };
    case ACTIVO_RAZAS:
      return {
        ...state,
        activo: action.payload,
      };
    case LOADING_ALL_RAZAS:
      return {
        ...state,
        loadingAll: action.payload,
      };
    default:
      return state;
  }
};
