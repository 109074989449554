export const DOGOUT = 'DOGOUT';
export const TOKEN = `${DOGOUT}Token`;
export const EMAIL = `${DOGOUT}Email`;
export const I18N = `${DOGOUT}I18n`;
export const I18N_LNG = `${DOGOUT}I18Lng`;
export const REMEMBER_ME = `${DOGOUT}RemenberMe`;
export const PERSIST = `persist:${DOGOUT}`;

export const SU = 'SUPER';
export const ADMIN = 'ADMIN';
export const ENTITY = 'entity';
export const CLIENT = 'CLIENT';
export const SERVICE = 'SERVICE';
