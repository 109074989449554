import { Grid, Stack, Typography } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import * as Redux from 'react-redux';
import { DeleteModalComponent, LoadingWidget } from '../../../components';
import { getCountry } from '../../../components/fields/CountryCode';
import {
  deleteTarifasAction,
  getAllTarifasAction,
  getOneTarifasAction,
  setActivoTarifasAction,
} from '../../../store/Actions';
import { NameComponent, textLabels } from '../../../utils';
import { useNotification } from '../../../utils/Notification';
import BotonNuevoComponent from '../../dashboard/components/BotonNuevo';
import { ID } from '../../usuarios/UsuariosConst';

const ListadoTarifas = ({ setModalForm, setIsInfo }) => {
  const dispatch = Redux.useDispatch();
  const { notify } = useNotification();

  const tarifasStore = Redux.useSelector((state) => state.tarifas);

  const [isDelete, setIsDelete] = React.useState(false);
  const [loadingDelete, setLoadingDelete] = React.useState(false);
  const [rows, setRows] = React.useState([]);

  React.useEffect(() => {
    setRows([]);
    tarifasStore.all.forEach((data) => {
      setRows((old) => [
        ...old,
        {
          ...data,
          pais: getCountry(data.paisPhone),
          costo: `${data.simbolo} ${data.costo}`,
        },
      ]);
    });
  }, [tarifasStore.all]);

  React.useEffect(() => {
    if (tarifasStore.all && tarifasStore.all.length === 0) {
      getAllTarifasAction(dispatch);
    }

    // eslint-disable-next-line
  }, []);

  function findData(id) {
    const data = tarifasStore.all.find((el) => el.id === id);
    setActivoTarifasAction(dispatch, data);
    return data;
  }

  const _onInfo = async (id) => {
    const res = findData(id);
    await getOneTarifasAction({ dispatch, notify }, res.id);
    setModalForm(true);
  };

  const _onDelete = async () => {
    setLoadingDelete(true);
    await deleteTarifasAction({ dispatch, notify }, tarifasStore.activo.id);
    setIsDelete(false);
    setLoadingDelete(false);
  };

  const columns = [
    {
      name: ID,
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'paseo',
      label: 'Paseo',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NameComponent onClick={() => _onInfo(tableMeta.rowData[0])}>
              {value.nombre} ({value.modalidad}) {value.recomendado ? '🌟' : ''}
            </NameComponent>
          );
        },
      },
    },
    {
      name: 'pais',
      label: 'País',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography>
              +({value?.phone}) {value?.label}
            </Typography>
          );
        },
      },
    },
    {
      name: 'costo',
      label: 'Costo',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Typography>{value}</Typography>;
        },
      },
    },
    {
      name: '',
      label: '',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Stack direction="row" spacing={2} sx={{ width: '200px' }}>
              <BotonNuevoComponent
                click={() => {
                  findData(tableMeta.rowData[0]);
                  setModalForm(true);
                }}
                text="Editar"
                azul
              />
              <BotonNuevoComponent
                text="Eliminar"
                rojo
                click={() => {
                  findData(tableMeta.rowData[0]);
                  setIsDelete(true);
                }}
              />
            </Stack>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    responsive: 'scroll',
    setTableProps: () => {
      return {
        padding: 'none',
        size: 'small',
      };
    },
    print: false,
    download: false,
    textLabels,
    rowsPerPageOptions: [50, 100, 150],
    selectableRowsHideCheckboxes: true,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      return <div></div>;
    },
  };

  return (
    <Grid container>
      <Grid item md={12}>
        <LoadingWidget isLoading={tarifasStore.loadingAll || loadingDelete} />
        <MUIDataTable
          title={'Listado'}
          data={rows}
          columns={columns}
          options={options}
        />
      </Grid>
      {tarifasStore.activo && (
        <DeleteModalComponent
          open={isDelete}
          onClose={() => setIsDelete(!isDelete)}
          onDelete={_onDelete}
        >
          <Typography variant="h6">
            Paseo: {tarifasStore.activo?.paseo.nombre}{' '}
            {tarifasStore.activo.paseo.recomendado ? '🌟' : ''}
          </Typography>
          <Typography variant="h6">
            País: +({getCountry(tarifasStore.activo?.paisPhone).phone}){' '}
            {getCountry(tarifasStore.activo?.paisPhone).label}
          </Typography>
          <Typography variant="h6">
            Costo: {tarifasStore.activo.simbolo} {tarifasStore.activo.costo}{' '}
          </Typography>
        </DeleteModalComponent>
      )}
    </Grid>
  );
};

export default ListadoTarifas;
