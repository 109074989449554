import axios from 'axios';
import { baseUrl, token } from '../../services/Apis';

export const paseosApi = `${baseUrl}/paseos`;

export const getAllPaseosService = async (params) => {
  return await axios.get(paseosApi, {
    headers: await token({}),
    params,
  });
};

export const savePaseosService = async (id, data) => {
  if (id) {
    return await axios.patch(`${paseosApi}/${id}`, data, {
      headers: await token({}),
    });
  }

  return await axios.post(paseosApi, data, {
    headers: await token({}),
  });
};

export const getOnePaseosService = async (id) => {
  return await axios.get(`${paseosApi}/${id}`, {
    headers: await token({}),
  });
};

export const deletePaseosService = async (id, idEnt) => {
  return await axios.delete(`${paseosApi}/${id}`, {
    headers: await token({}),
  });
};
