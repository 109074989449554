import * as React from 'react';
import {
    Collapse,
    List as ListM,
    ListItemButton,
    ListItemText,
    Tooltip,
} from '@mui/material';
import {
    ExpandLess,
    ExpandMore,
} from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
import { blue, grey } from '@mui/material/colors';
import { ROJO_COLOR } from '../../../constants/ColorsConst';

const ItemDrawer = ({ item, active, openDrawer }) => {

    const location = useLocation()

    const [open, setOpen] = React.useState(false);

    const handleClick = () => setOpen(!open);

    const xLink = (i, t, to) => {
        const active = location.pathname.includes(to)
        return <Link
            key={t}
            to={to}
            style={{
                color: 'black',
                textDecoration: 'none',
            }}
        >
            <ListItemButton
                sx={{
                    backgroundColor: active ? ROJO_COLOR : 'transparent',
                    color: active ? 'white' : 'black',
                    mx: 1,
                    mb: 0.3,
                    borderRadius: '7px',
                    "&:hover": {
                        backgroundColor: grey[300],
                        color: 'black',
                    },
                }}
                dense={true}
            >
                <Tooltip
                    title={t}
                >
                    {i}
                </Tooltip>
                <ListItemText primary={t} sx={{ ml: 3 }} />
            </ListItemButton>
        </Link>
    }

    const xCollapse = (i) => {
        return <>
            <ListItemButton
                onClick={handleClick}
                dense={true}
                sx={{
                    backgroundColor: active ? '#e6e6e6' : 'transparent',
                    "&:hover": {
                        backgroundColor: 'lightblue'
                    },
                }}
            >
                <div style={{
                    width: '60px',
                    height: '60px',
                }}>
                    {i.icon}
                </div>
                <ListItemText primary={i.title} sx={{ ml: 2 }} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <ListM component="div" disablePadding>
                    {
                        i.vistas.map((route) => {
                            return xLink(route.icon, route.title, route.to)
                        })
                    }
                </ListM>
            </Collapse>
        </>
    }

    return item.vistas && item.vistas.length > 0 ?
        xCollapse(item)
        : xLink(item.icon, item.title, item.to)
};


export default ItemDrawer;