import axios from 'axios';
import { baseUrl, token } from '../../services/Apis';

export const tarifasApi = `${baseUrl}/tarifas`;

const TarifaService = {
  getAll: async (params) => {
    return await axios.get(tarifasApi, {
      headers: await token({}),
      params,
    });
  },
  getByOne: async (id) => {
    return await axios.get(`${tarifasApi}/${id}`, {
      headers: await token({}),
    });
  },
  save: async (id, data) => {
    if (id) {
      return await axios.patch(`${tarifasApi}/${id}`, data, {
        headers: await token({}),
      });
    }

    return await axios.post(tarifasApi, data, {
      headers: await token({}),
    });
  },
  delete: async (id) => {
    return await axios.delete(`${tarifasApi}/${id}`, {
      headers: await token({}),
    });
  },
};

export default TarifaService;
