import {
  EMAIL,
  PERSIST,
  REMEMBER_ME,
  TOKEN,
} from '../../../constants/ConfigConst';
import * as ROUTES from '../../../router/Routes';
import { SET_LOGOUT, SET_USER } from '../../../store/Types';
import { loginService } from './LoginService';

export const loginAction = async (data, { dispatch, history }) => {
  const res = await loginService({ user: data.user, password: data.password });

  if (res.status >= 400 || res.status === undefined || res.status === null)
    return res.message ?? 'Hubo un error en el inicio de sesión';

  if (res.status >= 200 && res.status < 203) {
    localStorage.setItem(TOKEN, res.data.accessToken);
    localStorage.setItem(EMAIL, data.email);
    localStorage.setItem(REMEMBER_ME, data.isRemember ? 1 : 0);
    dispatch({
      type: SET_USER,
      payload: res.data,
    });
    history.push(ROUTES.dashboard.to);
  }
};

export const logoutAction = async ({ dispatch, history }) => {
  localStorage.removeItem(TOKEN);
  localStorage.removeItem(PERSIST);
  history.push(ROUTES.login.to);
  dispatch({
    type: SET_LOGOUT,
  });
};
