import { TextField } from '@mui/material';
import PasswordField from './components/PasswordField';

export const ID = 'id';
export const USER = 'user';
export const EMAIL = 'email';
export const USERNAME = 'username';
export const CORREO = 'correo';
export const PASSWORD = 'password';
export const CODE = 'code';
export const PERFIL = 'perfil';
export const VERIFICADO = 'verificado';
export const INFORMACION = 'informacion';
export const ESTATUS = 'estatus';
export const CREATED_BY = 'createdBy';
export const CREATED_AT = 'createdAt';
export const UPDATED_BY = 'updatedBy';
export const UPDATED_AT = 'updatedAt';

export const fieldsUsuario = (
  field,
  { value, onChange, error, required = true, onClick, showPassword }
) => {
  switch (field) {
    case USER:
      return (
        <TextField
          required={required}
          fullWidth
          id={USER}
          label="Usuario"
          name={USER}
          value={value}
          autoComplete={USER}
          onChange={onChange}
          autoFocus
          error={error}
          sx={{ mb: 2 }}
        />
      );
    case PASSWORD:
      return (
        <PasswordField
          value={value}
          error={error}
          onClick={onClick}
          showPassword={showPassword}
          onChange={onChange}
        />
      );
  }
};
