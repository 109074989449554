import { Grid } from '@mui/material';
import * as React from 'react';
import * as Redux from 'react-redux';
import ModalComponent from '../../components/modals/Modal';
import {
  getAllTarifasAction,
  setActivoTarifasAction,
} from '../../store/Actions';
import BotonNuevoComponent from '../dashboard/components/BotonNuevo';
import FormTarifas from './components/FormTarifas';
import ListadoTarifas from './components/ListadoTarifas';

const TarifasView = () => {
  const dispatch = Redux.useDispatch();

  const tarifasStore = Redux.useSelector((state) => state.tarifas);

  const [modalForm, setModalForm] = React.useState(false);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <BotonNuevoComponent
          click={() => {
            setActivoTarifasAction(dispatch, null);
            setModalForm(true);
          }}
          verde
          mr={2}
        />
        <BotonNuevoComponent
          click={async () => {
            await setActivoTarifasAction(dispatch, null);
            await getAllTarifasAction(dispatch);
          }}
          white
          disabled={tarifasStore.loadingAll || tarifasStore.all.length === 0}
          text={tarifasStore.loadingAll ? 'Actualizando...' : 'Actualizar'}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <ListadoTarifas setModalForm={setModalForm} />
      </Grid>
      <ModalComponent
        id="modalFormTarifas"
        title={!tarifasStore.activo ? 'Nueva tarifa' : `Editar tarifa`}
        open={modalForm}
        maxWidth="sm"
        onClose={() => setModalForm(!modalForm)}
      >
        {tarifasStore.activo ? (
          <FormTarifas
            activo={tarifasStore.activo}
            setModalForm={setModalForm}
          />
        ) : (
          <FormTarifas setModalForm={setModalForm} />
        )}
      </ModalComponent>
    </Grid>
  );
};

export default TarifasView;
