import React from 'react';
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import Template from '../components/template';
import { TOKEN } from '../constants/ConfigConst';
import {
  ClientesModule,
  DashboardModule,
  LoginModule,
  PaseadoresModule,
  PedidosModule,
  RazasModule,
  SolicitudesModule,
  TarifasModule,
  UsuariosModule,
} from './Pages';
import * as ROUTES from './Routes';

const Routers = () => {
  return (
    <Router>
      <Switch>
        <PublicPage path="/" exact>
          <LoginModule />
        </PublicPage>
        <PublicPage path={ROUTES.login.to} exact>
          <LoginModule />
        </PublicPage>
        {/* <PublicPage path={ROUTES.recover.to} exact>
          <RecoverModule />
        </PublicPage> */}
        {/* <PublicPage path={ROUTES.confirmEmail.to} exact>
          <ConfirmEmailModule />
        </PublicPage> */}
        {/* <PublicPage path={ROUTES.register.to} exact >
					<RegisterPage />
				</PublicPage> */}

        <Template>
          <Switch>
            <PrivatePage path={ROUTES.dashboard.to}>
              <DashboardModule />
            </PrivatePage>
            <PrivatePage path={ROUTES.usuarios.to}>
              <UsuariosModule />
            </PrivatePage>
            <PrivatePage path={ROUTES.paseadores.to}>
              <PaseadoresModule />
            </PrivatePage>
            <PrivatePage path={ROUTES.solicitudes.to}>
              <SolicitudesModule />
            </PrivatePage>
            <PrivatePage path={ROUTES.razas.to}>
              <RazasModule />
            </PrivatePage>
            <PrivatePage path={ROUTES.pedidos.to}>
              <PedidosModule />
            </PrivatePage>
            <PrivatePage path={ROUTES.clientes.to}>
              <ClientesModule />
            </PrivatePage>
            <PrivatePage path={ROUTES.tarifas.to}>
              <TarifasModule />
            </PrivatePage>
          </Switch>
        </Template>
      </Switch>
    </Router>
  );
};

const validarToken = () => {
  const token = localStorage.getItem(TOKEN);
  let isValid = false;
  try {
    isValid = token && token !== '';
  } catch (e) {
    return false;
  }
  return isValid;
};

const PublicPage = ({ children, ...props }) => {
  let isAuthenticated = validarToken();
  return !isAuthenticated ? (
    <Route {...props}> {children} </Route>
  ) : (
    <Redirect to={ROUTES.dashboard.to} />
  );
};

const PrivatePage = ({ children, ...props }) => {
  let isAuthenticated = validarToken();
  return isAuthenticated ? (
    <Route {...props}> {children} </Route>
  ) : (
    <Redirect to={ROUTES.login.to} />
  );
};

export default Routers;
