import { Grid } from '@mui/material';
import * as React from 'react';
import * as Redux from 'react-redux';
import ModalComponent from '../../components/modals/Modal';
import {
  getAllPedidosAction,
  setActivoPedidosAction,
} from '../../store/Actions';
import BotonNuevoComponent from '../dashboard/components/BotonNuevo';
import DetallesPedidos from './components/DetallesPedidos';
import ListadoPedidos from './components/ListadoPedidos';

const PedidosView = () => {
  const dispatch = Redux.useDispatch();

  const pedidosStore = Redux.useSelector((state) => state.pedidos);

  const [modalForm, setModalForm] = React.useState(false);
  const [isInfo, setIsInfo] = React.useState(false);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        {/* <BotonNuevoComponent
          click={() => {
            setActivoPedidosAction(dispatch, null);
            setModalForm(true);
          }}
          verde
          mr={2}
        /> */}
        <BotonNuevoComponent
          click={async () => {
            await setActivoPedidosAction(dispatch, null);
            await getAllPedidosAction(dispatch);
          }}
          white
          text={pedidosStore.loadingAll ? 'Actualizando...' : 'Actualizar'}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <ListadoPedidos setModalForm={setModalForm} />
      </Grid>
      <ModalComponent
        id="modalFormPedidos"
        title="Pedido"
        open={modalForm}
        onClose={() => setModalForm(!modalForm)}
      >
        {pedidosStore.activo && <DetallesPedidos setModalForm={setModalForm} />}
      </ModalComponent>
    </Grid>
  );
};

export default PedidosView;
