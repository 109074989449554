import {
  Avatar,
  AvatarGroup,
  Box,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import * as React from 'react';
import * as Redux from 'react-redux';
import { urlImage } from '../../../utils';

const DetallesPedidos = () => {
  const pedidosStore = Redux.useSelector((state) => state.pedidos);
  const activo = pedidosStore.activo;

  const fullname = `${activo.cliente.informacion.pnombre} ${activo.cliente.informacion.papellido}`;

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Typography variant="h5" color="initial">
          Servicio
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <Typography variant="h6" color="initial">
              {activo.servicio.nombre}
            </Typography>
            <Typography variant="body1" color="initial">
              <strong>Paseo: </strong>
              {activo.movimiento.paseo.nombre}
            </Typography>
            <Typography variant="body1" color="initial">
              <strong>Descuento: </strong>
              {activo.movimiento.descuento}
            </Typography>
            <Typography variant="body1" color="initial">
              <strong>Método de pago: </strong>
              {activo.movimiento.tipoPago}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <Typography variant="h5" color="initial">
              Cliente
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
            >
              <Avatar
                alt={activo.cliente.informacion.foto ?? 'myPhotoClient'}
                sx={{ width: 80, height: 80 }}
                src={urlImage(activo.cliente.informacion.foto)}
              />
              <Box sx={{ marginLeft: 1 }}>
                <Typography variant="body1" color="initial">
                  <strong>
                    {activo.cliente.informacion.dni}-{fullname}
                  </strong>
                </Typography>
                <Typography variant="body1" color="initial">
                  <strong>Correo: </strong> {activo.cliente.correo}
                </Typography>
                <Typography variant="body1" color="initial">
                  <strong>Teléfono: </strong>{' '}
                  {activo.cliente.informacion.telefono}
                </Typography>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} md={12}>
            <Box sx={{ display: 'flex' }}>
              <AvatarGroup max={6} sx={{ paddingRight: 2 }}>
                {activo.mascotas.map((el) => {
                  return (
                    <Tooltip title={el.mascota.nombre} placement="top">
                      <Avatar
                        key={el.mascota.id}
                        alt={el.mascota.nombre}
                        src={urlImage(el.mascota.image)}
                        // onClick={() => {
                        //   setMascotaActiva(el);
                        //   setModalMascota(true);
                        // }}
                        sx={{ width: 60, height: 60 }}
                      />
                    </Tooltip>
                  );
                })}
              </AvatarGroup>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DetallesPedidos;
