import axios from 'axios';
import { baseUrl, token } from '../../services/Apis';
import { usuariosApi } from '../usuarios/UsuariosService';

export const paseadoresApi = `${baseUrl}/paseadores`;

export const getAllPaseadoresService = async (params) => {
  const res = await axios.get(paseadoresApi, {
    headers: await token({}),
    params: {
      idEstatus: 1,
      ...params,
    },
  });
  return res;
};

export const savePaseadoresService = async (id, data) => {
  if (id) {
    return await axios.patch(`${paseadoresApi}/${id}`, data, {
      headers: await token({}),
    });
  }
  return await axios.post(paseadoresApi, data, {
    headers: await token({}),
  });
};

export const getOnePaseadoresService = async (id) => {
  const res = await axios.get(`${paseadoresApi}/${id}`, {
    headers: await token({}),
  });
  return res;
};

export const deletePaseadoresService = async (id, idEnt) => {
  const res = await axios.delete(`${paseadoresApi}/${id}`, {
    headers: await token({}),
  });
  return res;
};

export const aprobarPaseadoresService = async (data) => {
  let res = await axios.patch(`${usuariosApi}/estatus`, data, {
    headers: await token({
      isToken: true,
    }),
  });

  return res;
};
