import React from 'react';
import PropTypes from 'prop-types';
import {
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput
} from '@mui/material';
import {
    Visibility,
    VisibilityOff
} from '@mui/icons-material';
import { PASSWORD } from '../UsuariosConst';

const PasswordField = ({
    value,
    error,
    onClick,
    showPassword,
    onChange,
    size,
    mtl,
    req,
    disabled,
    autoComplete,
    label
}) => {
    return (
        <FormControl
            fullWidth
            variant="outlined"
            sx={{ mb: 2 }}
        >
            <InputLabel htmlFor={PASSWORD} sx={{ marginTop: mtl ?? 0 }}>{`${label || 'Password'} ${req === null ? '*' : req ? '*' : ''}`}</InputLabel>
            <OutlinedInput
                id={PASSWORD}
                type={showPassword ? 'text' : 'password'}
                label={`${label || 'Password'} ${req === null ? '*' : req ? '*' : ''}`}
                disabled={disabled}
                value={value}
                onChange={onChange}
                required={req ?? true}
                size={size}
                error={error}
                autoComplete={autoComplete}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={onClick}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }
            />
        </FormControl>
    );
};

PasswordField.propTypes = {
    onClick: PropTypes.func,
    onChange: PropTypes.func.isRequired
};

export default PasswordField;