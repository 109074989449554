import React from 'react';
import ContainerTemplate from '../../components/template/Container';
import * as ROUTES from '../../router/Routes';
import RazasView from './RazasView';

const RazasModule = () => {
  return (
    <ContainerTemplate active={ROUTES.razas} routes={[]}>
      <RazasView />
    </ContainerTemplate>
  );
};

export default RazasModule;
