import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Avatar,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import * as Redux from 'react-redux';
import { LoadingWidget, Modal } from '../../../components';
import ActionsList from '../../../components/ActionsList';
import {
  NARANJA_COLOR,
  ROJO_COLOR,
  VERDE_COLOR,
} from '../../../constants/ColorsConst';
import { ACTIONS } from '../../../constants/FieldsConst';
import { NameComponent, textLabels, urlImage } from '../../../utils';
import BotonNuevoComponent from '../../dashboard/components/BotonNuevo';
import * as CONST_USUINF from '../UsuInformacionConst';
import * as CONST_USU from '../UsuariosConst';
import ExpandeRowUsuarios from './ExpandedRowUsuarios';

const rolString = {
  client: 'Cliente',
  walker: 'Paseador',
  admin: 'Administrador',
};

const ListadoUsuarios = ({ setOpenModalForm }) => {
  // const dispatch = Redux.useDispatch();
  // let history = useHistory();

  const usuariosStore = Redux.useSelector((state) => state.usuarios);

  const [rows, setRows] = React.useState([]);
  const [isDelete, setIsDelete] = React.useState(false);

  React.useEffect(() => {
    setRows([]);
    usuariosStore.all.map((user) => {
      setRows((old) => [
        ...old,
        {
          [CONST_USU.ID]: user.id,
          [CONST_USUINF.FOTO]: user.informacion.foto,
          [CONST_USUINF.DNI]: user.informacion.dni,
          [CONST_USU.CODE]: user.code,
          [CONST_USUINF.NOMBRE]: `${user.informacion.pnombre} ${user.informacion.papellido}`,
          [CONST_USU.CORREO]: user.correo,
          [CONST_USU.PERFIL]: rolString[user.rol],
          [CONST_USU.ESTATUS]: { ...user.estatus },
        },
      ]);
    });
  }, [usuariosStore.all]);

  // const _handleInfoClick = async (id) => {
  //     const user = usuariosStore.all.find(el => el.id === id);
  //     await setActivoUsuariosAction(dispatch, user)
  //     history.push(Routes.usuariosInfo.to);
  // }

  const _onEdit = async (id) => {
    // const user = usuariosStore.all.find(el => el.id === id);
    // await setActivoUsuariosAction(dispatch, user)
    // dispatch({
    //     type: GET_USUARIOS,
    //     payload: null,
    // })
    // setOpenModalForm(true)
  };

  const _onDelete = async () => {
    // if (usuariosStore.activo.attributes.user_code.length === 0) {
    //     alert('usuario no tiene código asigando')
    //     return
    // }
    // setLoadingEditar(true)
    // await unassignUsuariosAction(dispatch, entidadesStore.activo.id, usuariosStore.activo.id, usuariosStore.activo.attributes.user_code[0].id)
    // setIsDelete(false)
    // setLoadingEditar(false)
  };

  const columns = [
    {
      name: CONST_USU.ID,
      options: {
        display: 'excluded',
      },
    },
    {
      name: CONST_USUINF.FOTO,
      label: 'Foto',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Avatar
              alt={value?.nombre ?? 'myPhoto'}
              sx={{ width: 30, height: 30 }}
              src={urlImage(value)}
            />
          );
        },
      },
    },
    {
      name: CONST_USUINF.DNI,
      label: 'Ident.',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography
              variant="body"
              component="p"
              sx={{ fontWeight: 'bold' }}
            >
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: CONST_USUINF.NOMBRE,
      label: 'Nombre y apellido',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <NameComponent onClick={() => {}}> {value} </NameComponent>;
        },
      },
    },
    {
      name: CONST_USU.CORREO,
      label: 'Correo',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Typography variant="body">{value}</Typography>;
        },
      },
    },
    {
      name: CONST_USU.PERFIL,
      label: 'Perfil',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Typography variant="body">{value}</Typography>;
        },
      },
    },
    {
      name: CONST_USU.CODE,
      label: 'PIN',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Typography variant="body">{value ?? '-'}</Typography>;
        },
      },
    },
    {
      name: CONST_USU.ESTATUS,
      label: 'Estatus',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip describeChild title="Estatus del usuario" placement="left">
              <BotonNuevoComponent
                text={value.nombre}
                color={
                  value.id === 1
                    ? VERDE_COLOR
                    : value.id === 4
                    ? NARANJA_COLOR
                    : ROJO_COLOR
                }
              />
            </Tooltip>
          );
        },
      },
    },
    {
      name: ACTIONS,
      label: 'Acciones',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box align="left" sx={{ display: 'flex', flexDirection: 'row' }}>
                {usuariosStore.isAllDogout ? null : (
                  <Tooltip title="Información">
                    <IconButton
                      aria-label="edit"
                      size="small"
                      color="primary"
                      onClick={() => {}}
                    >
                      <VisibilityIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                )}
                <ActionsList
                  id={tableMeta.rowData[0]}
                  onEdit={
                    usuariosStore.isAllDogout
                      ? null
                      : () => _onEdit(tableMeta.rowData[0])
                  }
                  onDelete={async () => {
                    // if (usuariosStore.isAllDogout) {
                    //   alert(
                    //     'verificar url de eliminar definitivamente el usuario del sistema'
                    //   );
                    //   return;
                    // }
                    // const data = usuariosStore.all.find(
                    //   (el) => el.id === tableMeta.rowData[0]
                    // );
                    // await setActivoUsuariosAction(dispatch, data)
                    setIsDelete(true);
                  }}
                />
              </Box>
            </>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    count: 15,
    rowsPerPageOptions: [15, 30, 50, 100],
    setTableProps: () => {
      return {
        padding: 'none',
        size: 'small',
      };
    },
    responsive: 'scroll',
    selectableRowsHideCheckboxes: true,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      return (
        <ExpandeRowUsuarios
          data={usuariosStore.all.find((el) => el.id === rowData[0])}
          length={rowData.length}
        />
      );
    },
    textLabels,
  };

  return (
    <Grid container>
      <Grid item md={12}>
        <LoadingWidget isLoading={usuariosStore.loadingAll} />
        <MUIDataTable
          title={'Listado'}
          data={rows}
          columns={columns}
          options={options}
        />
      </Grid>
      {usuariosStore.activo ? (
        <Modal
          id="modalFormEliminarUsuarios"
          title={`Eliminar`}
          open={isDelete}
          maxWidth="sm"
          onClose={() => setIsDelete(!isDelete)}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-eliminar-usuarios">
              ¿Seguro que quieres eliminar este usuario?
              <p>
                <strong>
                  {
                    usuariosStore.activo.attributes.profile
                      .identification_number
                  }
                  {'  '}
                  {usuariosStore.activo.attributes.profile.first_name}
                  {'  '}
                  {usuariosStore.activo.attributes.profile.first_last_name}
                </strong>
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="outlined"
              onClick={async () => {
                await _onDelete();
              }}
            >
              ELIMINAR
            </Button>
            <Button
              sx={{ color: ROJO_COLOR }}
              onClick={() => setIsDelete(false)}
            >
              CANCELAR
            </Button>
          </DialogActions>
        </Modal>
      ) : null}
    </Grid>
  );
};

export default ListadoUsuarios;
