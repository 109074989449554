import * as React from 'react';
import { Grid } from '@mui/material';
import BreadcrumbTemplate from './Breadcrumb';

const ContainerTemplate = ({ children, active, routes }) => {
    return (
        <Grid container>
            <BreadcrumbTemplate
                active={active}
                routes={routes}
            />
            {children}
        </Grid>
    );
};

export default ContainerTemplate;