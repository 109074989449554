import { Box, Grid, TableCell, TableRow } from '@mui/material';
import * as React from 'react';
import Modal from '../../../components/modals/Modal';
import { urlImage } from '../../../utils';

const ExpandeRowAreas = ({ data, length }) => {
  const [openModalForm, setOpenModalForm] = React.useState(false);

  const info = data.informacion;
  const foto = info.foto;

  return (
    <TableRow>
      <TableCell sx={{ padding: '15px' }} colSpan={length}>
        <Grid container spacing={2}>
          <Grid item md={1}>
            {foto ? (
              <img
                src={urlImage(foto)}
                width="100%"
                onClick={() => setOpenModalForm(true)}
                sx={{ cursor: 'pointer' }}
              />
            ) : null}
          </Grid>
          <Grid item md={11}>
            <Grid container spacing={1}>
              <Grid item md={2}>
                {/* <Typography variant="body" sx={{ fontWeight: '700' }} >Fecha de Nacimiento:&nbsp;&nbsp;</Typography> */}
              </Grid>
              <Grid item md={2}>
                {/* <Typography variant="body" sx={{}} >
                                    {data.informacion.fechaNacimiento}
                                </Typography> */}
              </Grid>
              <Grid item md={2}>
                {/* <Typography variant="body" sx={{ fontWeight: '700' }} >Tipo de Sangre:&nbsp;&nbsp;</Typography> */}
              </Grid>
              <Grid item md={2}>
                {/* <Typography variant="body" sx={{}} >{data.informacion.tipoSangre}</Typography> */}
              </Grid>
              <Grid item md={1}>
                {/* <Typography variant="body" sx={{ fontWeight: '700' }} >Género:&nbsp;&nbsp;</Typography> */}
              </Grid>
              <Grid item md={3}>
                {/* <Typography variant="body" sx={{}} >{data.informacion.genero}</Typography> */}
              </Grid>
              <Grid item md={1}>
                {/* <Typography variant="body" sx={{ fontWeight: '700' }} >Edad:&nbsp;&nbsp;</Typography> */}
              </Grid>
              <Grid item md={3}>
                {/* <Typography variant="body" sx={{}} >{calculateAge(data.informacion.fechaNacimiento)}</Typography> */}
              </Grid>
              {/* <Grid item md={1}>
                                <Typography variant="body" sx={{ fontWeight: '700' }} >Dirección:&nbsp;&nbsp;</Typography>
                            </Grid>
                            <Grid item md={7}>
                                <Typography
                                    variant="body"
                                    sx={{ color: data.informacion.direccion ? 'black' : 'red' }}
                                >{data.informacion.direccion ? data.informacion.direccion.direccion : 'N/A'}</Typography>
                            </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </TableCell>
      <Modal
        id="fotoArea"
        title={`${info.pnombre} ${info.papellido}`}
        open={openModalForm}
        onClose={() => setOpenModalForm(false)}
        maxWidth="sm"
        isCloseBackdrop
      >
        <Box sx={{ width: '100%' }} align="center">
          <img src={urlImage(foto)} width="80%" />
        </Box>
      </Modal>
    </TableRow>
  );
};

ExpandeRowAreas.propTypes = {};

export default ExpandeRowAreas;
