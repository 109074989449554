import { Grid, Typography } from '@mui/material';
import * as React from 'react';
import * as Redux from 'react-redux';
import { LoadingWidget } from '../../components';
import ModalComponent from '../../components/modals/Modal';
import { ROJO_COLOR, VERDE_COLOR } from '../../constants/ColorsConst';
import {
  aprobarSolicitudesAction,
  getAllSolicitudesAction,
  setActivoSolicitudesAction,
} from '../../store/Actions';
import { ACTIVO_SOLICITUDES } from '../../store/Types';
import { urlImage } from '../../utils';
import { useNotification } from '../../utils/Notification';
import BotonNuevoComponent from '../dashboard/components/BotonNuevo';
import ListadoSolicitudes from './components/ListadoSolicitudes';

const SolicitudesView = () => {
  const dispatch = Redux.useDispatch();
  const { notify } = useNotification();

  const solicitudesStore = Redux.useSelector((state) => state.solicitudes);

  const [modalForm, setModalForm] = React.useState(false);
  const [isInfo, setIsInfo] = React.useState(false);
  const [imgActivo, setImgActivo] = React.useState();

  const fullname = `${solicitudesStore.activo?.usuario.informacion.pnombre} ${
    solicitudesStore.activo?.usuario.informacion.snombre ?? ''
  } ${solicitudesStore.activo?.usuario.informacion.papellido} ${
    solicitudesStore.activo?.usuario.informacion.sapellido ?? ''
  }`;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        {/* <BotonNuevoComponent
          click={() => {
            setActivoSolicitudesAction(dispatch, null);
            setModalForm(true);
          }}
          verde
          mr={2}
        /> */}
        <BotonNuevoComponent
          click={async () => {
            await setActivoSolicitudesAction(dispatch, null);
            await getAllSolicitudesAction(dispatch);
          }}
          white
          disabled={
            solicitudesStore.loadingAll || solicitudesStore.all.length === 0
          }
          text={solicitudesStore.loadingAll ? 'Actualizando...' : 'Actualizar'}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <ListadoSolicitudes setModalForm={setModalForm} setIsInfo={setIsInfo} />
      </Grid>
      <ModalComponent
        id="modalFormSolicitudes"
        title={`Paseador: ${fullname}`}
        open={modalForm}
        maxWidth="lg"
        onClose={() => setModalForm(!modalForm)}
      >
        {/* {solicitudesStore.activo ? (
          <FormSolicitudes
            activo={solicitudesStore.activo}
            setModalForm={setModalForm}
            setIsInfo={setIsInfo}
          />
        ) : (
          <FormSolicitudes setModalForm={setModalForm} setIsInfo={setIsInfo} />
        )} */}

        {solicitudesStore.loadingAll ? (
          <LoadingWidget
            isLoading={solicitudesStore.loadingAll}
            sx={{ mb: 2 }}
          />
        ) : (
          <Grid container>
            <Grid item md={12}>
              <BotonNuevoComponent
                click={async () => {
                  const idPas = solicitudesStore.activo?.usuario.id;
                  const res = await aprobarSolicitudesAction(
                    { dispatch, notify },
                    {
                      idUsuario: idPas,
                      idEstatus:
                        solicitudesStore.activo?.usuario.estatus.id === 1
                          ? 5
                          : 1,
                    },
                    idPas
                  );
                  if (res) {
                    setModalForm(false);
                    dispatch({
                      type: ACTIVO_SOLICITUDES,
                      payload: null,
                    });
                  }
                }}
                text={
                  solicitudesStore.activo?.usuario.estatus.id === 1
                    ? 'Cambiar el usuario a pendiente'
                    : 'Aprobar la solitud'
                }
                color={
                  solicitudesStore.activo?.usuario.estatus.id === 1
                    ? ROJO_COLOR
                    : VERDE_COLOR
                }
                mr={2}
              />
            </Grid>
            <Grid item md={4}>
              {solicitudesStore.activo?.fotoDniFron && (
                <Grid container>
                  <Grid item md={12}>
                    <Typography variant="h6">Imagen del frontal</Typography>
                    <img
                      src={urlImage(solicitudesStore.activo?.fotoDniFron)}
                      width="20%"
                      onClick={() =>
                        setImgActivo(solicitudesStore.activo?.fotoDniFron)
                      }
                      sx={{ cursor: 'pointer' }}
                    />
                  </Grid>
                  <Grid item md={12}>
                    <Typography variant="h6">Imagen del Posterior</Typography>
                    <img
                      src={urlImage(solicitudesStore.activo?.fotoDniPos)}
                      width="20%"
                      onClick={() =>
                        setImgActivo(solicitudesStore.activo?.fotoDniPos)
                      }
                      sx={{ cursor: 'pointer' }}
                    />
                  </Grid>
                  <Grid item md={12}>
                    <Typography variant="h6">Imagen del carnet</Typography>
                    <img
                      src={urlImage(solicitudesStore.activo?.fotoCarnet)}
                      width="20%"
                      onClick={() =>
                        setImgActivo(solicitudesStore.activo?.fotoCarnet)
                      }
                      sx={{ cursor: 'pointer' }}
                    />
                  </Grid>
                  <Grid item md={12}>
                    <Typography variant="h6">Imagen del certificado</Typography>
                    <img
                      src={urlImage(solicitudesStore.activo?.fotoCert)}
                      width="20%"
                      onClick={() =>
                        setImgActivo(solicitudesStore.activo?.fotoCert)
                      }
                      sx={{ cursor: 'pointer' }}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item md={7}>
              {imgActivo && (
                <img
                  src={urlImage(imgActivo)}
                  width="100%"
                  //   onClick={() => setOpenModalForm(true)}
                  sx={{ cursor: 'pointer' }}
                />
              )}
            </Grid>
          </Grid>
        )}
      </ModalComponent>
    </Grid>
  );
};

export default SolicitudesView;
