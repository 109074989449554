import { Grid, ImageList, ImageListItem, Typography } from '@mui/material';
import * as React from 'react';
import * as Redux from 'react-redux';
import ModalComponent from '../../components/modals/Modal';
import {
  getAllClientesAction,
  setActivoClientesAction,
} from '../../store/Actions';
import { urlImage } from '../../utils';
import { useNotification } from '../../utils/Notification';
import BotonNuevoComponent from '../dashboard/components/BotonNuevo';
import ListadoClientes from './components/ListadoClientes';

const ClientesView = () => {
  const dispatch = Redux.useDispatch();
  const { notify } = useNotification();

  const clientesStore = Redux.useSelector((state) => state.clientes);

  const [modalForm, setModalForm] = React.useState(false);
  const [isInfo, setIsInfo] = React.useState(false);
  const [imgActivo, setImgActivo] = React.useState();

  const fullname = `${clientesStore.activo?.informacion.pnombre} ${
    clientesStore.activo?.informacion.snombre ?? ''
  } ${clientesStore.activo?.informacion.papellido} ${
    clientesStore.activo?.informacion.sapellido ?? ''
  }`;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        {/* <BotonNuevoComponent
          click={() => {
            setActivoClientesAction(dispatch, null);
            setModalForm(true);
          }}
          verde
          mr={2}
        /> */}
        <BotonNuevoComponent
          click={async () => {
            await setActivoClientesAction(dispatch, null);
            await getAllClientesAction(dispatch);
          }}
          white
          disabled={clientesStore.loadingAll || clientesStore.all.length === 0}
          text={clientesStore.loadingAll ? 'Actualizando...' : 'Actualizar'}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <ListadoClientes setModalForm={setModalForm} setIsInfo={setIsInfo} />
      </Grid>
      <ModalComponent
        id="modalFormClientes"
        title={`Cliente: ${fullname} `}
        open={modalForm}
        maxWidth="lg"
        onClose={() => setModalForm(!modalForm)}
      >
        {/* {clientesStore.activo ? (
          <FormClientes
            activo={clientesStore.activo}
            setModalForm={setModalForm}
            setIsInfo={setIsInfo}
          />
        ) : (
          <FormClientes setModalForm={setModalForm} setIsInfo={setIsInfo} />
        )} */}
        {clientesStore.activo && (
          <Grid container>
            <Grid item md={4}>
              <Grid container>
                {clientesStore.activo?.informacion.foto && (
                  <Grid item md={12}>
                    <img
                      src={urlImage(clientesStore.activo?.informacion.foto)}
                      width="70%"
                      //   onClick={() => setOpenModalForm(true)}
                      sx={{ cursor: 'pointer' }}
                    />
                  </Grid>
                )}
                <Grid item md={12}>
                  <Typography variant="body1" color="initial">
                    <strong>Nro. de identificación: </strong>
                    {clientesStore.activo?.informacion.dni}
                  </Typography>
                </Grid>
                <Grid item md={12}>
                  <Typography variant="body1" color="initial">
                    <strong>Nombre y apellido: </strong>
                    {clientesStore.activo?.informacion.pnombre}{' '}
                    {clientesStore.activo?.informacion.papellido}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={8}>
              <ImageList
                sx={{ width: 600, height: 450 }}
                cols={3}
                rowHeight={170}
              >
                {clientesStore.activo.mascotas.map((mascota) => (
                  <ImageListItem key={mascota.id}>
                    <Typography
                      variant="body1"
                      color="initial"
                      textAlign="center"
                      fontWeight="bold"
                    >
                      {mascota.nombre}
                    </Typography>
                    <img
                      src={urlImage(mascota.image)}
                      alt={mascota.nombre}
                      loading="lazy"
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            </Grid>
          </Grid>
        )}
      </ModalComponent>
    </Grid>
  );
};

export default ClientesView;
