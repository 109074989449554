import {
  deletePedidosService,
  getAllPedidosService,
  getOnePedidosService,
  savePedidosService,
} from '../../services';
import {
  ACTIVO_PEDIDOS,
  ALL_PEDIDOS,
  LOADING_ALL_PEDIDOS,
} from '../../store/Types';
import { tryCatchHelper } from '../../utils';

export const getAllPedidosAction = async (dispatch) => {
  try {
    dispatch({ type: LOADING_ALL_PEDIDOS, payload: true });
    const res = await getAllPedidosService(null);
    dispatch({ type: ALL_PEDIDOS, payload: res.data });
    dispatch({ type: LOADING_ALL_PEDIDOS, payload: false });
    return res.data;
  } catch (e) {
    dispatch({ type: LOADING_ALL_PEDIDOS, payload: false });
    tryCatchHelper(e);
    return false;
  }
};

export const setActivoPedidosAction = async (dispatch, data) => {
  dispatch({ type: ACTIVO_PEDIDOS, payload: data });
};

export const savePedidosAction = async ({ dispatch, notify }, id, data) => {
  try {
    const res = await savePedidosService(id || null, data);
    notify(res.data.message);
    if (id) setActivoPedidosAction(dispatch, res.data.data);
    await getAllPedidosAction(dispatch, null);
    return res.data;
  } catch (e) {
    tryCatchHelper({ e, notify });
    return false;
  }
};

export const getOnePedidosAction = async ({ dispatch, notify }, id) => {
  try {
    dispatch({ type: LOADING_ALL_PEDIDOS, payload: true });
    const res = await getOnePedidosService(id);
    await setActivoPedidosAction(dispatch, res.data.data);
    dispatch({ type: LOADING_ALL_PEDIDOS, payload: false });
    return res.data.data;
  } catch (e) {
    dispatch({ type: LOADING_ALL_PEDIDOS, payload: false });
    tryCatchHelper({ e, notify });
    return false;
  }
};

export const deletePedidosAction = async ({ dispatch, notify }, id) => {
  try {
    const res = await deletePedidosService(id);
    notify(res.data.message);
    await setActivoPedidosAction(dispatch, null);
    await getAllPedidosAction(dispatch);
  } catch (e) {
    tryCatchHelper({ e, notify });
    return false;
  }
};
