import axios from 'axios';
import { baseUrl, isTokenValid, token } from '../../services/Apis';

export const usuariosApi = `${baseUrl}/usuarios`;

export const getAllUsuariosService = async (params) => {
  let url = usuariosApi;
  try {
    const res = await axios.get(url, {
      headers: await token({}),
      params,
    });
    return res;
  } catch (e) {
    isTokenValid(e);
    return e.response;
  }
};

export const saveUsuariosService = async (data) => {
  const res = await axios.post(usuariosApi, data, {
    headers: await token({}),
  });
  return res;
};

export const updateUsuariosService = async (data) => {
  const res = await axios.put(usuariosApi, data, {
    headers: await token({}),
  });
  return res;
};

export const getOneUsuariosService = async (id) => {
  const res = await axios.get(`${usuariosApi}/${id}`, {
    headers: await token(),
  });
  return res;
};
