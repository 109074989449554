import React from 'react';
import ContainerTemplate from '../../components/template/Container';
import * as ROUTES from '../../router/Routes';
import SolicitudesView from './SolicitudesView';

const SolicitudesModule = () => {
  return (
    <ContainerTemplate active={ROUTES.solicitudes} routes={[]}>
      <SolicitudesView />
    </ContainerTemplate>
  );
};

export default SolicitudesModule;
