import React from 'react';
import PropTypes from 'prop-types';
import { Box, Breadcrumbs, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { AZUL_COLOR, ROJO_COLOR } from '../../constants/ColorsConst';

const BreadcrumbTemplate = ({ active, routes }) => {
    return (
        <Grid
            container
            sx={{ mb: 1 }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
        >
            <Grid
                container
                item
                direction="row"
                alignItems="center"
                md
            >
                {active.icon}
                <Typography
                    variant="h5"
                    component="h2"
                    sx={{
                        ml: 1,
                        fontWeight: '700',
                    }}
                >{active.title}</Typography>
            </Grid>
            <Grid item>
                <Breadcrumbs aria-label="breadcrumb" sx={{ pt: 0 }}>
                    {
                        routes.map((item) => {
                            return (
                                <Link key={`brc_${item.title}`} style={{
                                    textDecoration: 'none',
                                    color: AZUL_COLOR,
                                }} to={item.to}>
                                    {item.title}
                                </Link>
                            )
                        })
                    }
                    <Typography color="text.primary">{active.title}</Typography>
                </Breadcrumbs>
            </Grid>
        </Grid>
    );
};

BreadcrumbTemplate.propTypes = {
};

export default BreadcrumbTemplate;