import axios from "axios";
import { baseUrl } from "../../../services/Apis";

export const AUTH = "auth";

export const loginService = async (data) => {
  const res = await axios
    .post(`${baseUrl}/${AUTH}/login`, JSON.stringify(data), {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response.data;
    });
  return res;
};
