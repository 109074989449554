export const SET_USER = 'SET_USER';
export const SET_LOGOUT = 'SET_LOGOUT';
export const SET_PERFIL = 'SET_PERFIL';
export const ERROR_VIEW_LOGIN = 'ERROR_VIEW_LOGIN';

export const GET_DASHBOARD = 'GET_DASHBOARD';
export const GET_ALL_ESTATUS = 'GET_ALL_ESTATUS';
export const GET_CLEAR_ALL = 'GET_CLEAR_ALL';

export const GET_ONPASS_USUARIOS = 'GET_ONPASS_USUARIOS';
export const LOADING_ALL_USUARIOS = 'LOADING_ALL_USUARIOS';
export const ALL_USUARIOS = 'ALL_USUARIOS';
export const SET_ACTIVO_USUARIOS = 'SET_ACTIVO_USUARIOS';
export const GET_BY_ATRIBUTOS_USUARIOS = 'GET_BY_ATRIBUTOS_USUARIOS';
export const LOADING_UPDATE_USUARIOS = 'LOADING_UPDATE_USUARIOS';
export const GET_USUARIOS = 'GET_USUARIOS';

export const ALL_PASEADORES = 'ALL_PASEADORES';
export const ACTIVO_PASEADORES = 'ACTIVO_PASEADORES';
export const LOADING_ALL_PASEADORES = 'LOADING_ALL_PASEADORES';
export const LOADING_ONE_PASEADORES = 'LOADING_ONE_PASEADORES';
export const MESSAGE_VIEW_PASEADORES = 'MESSAGE_VIEW_PASEADORES';
export const PARAMS_PASEADORES = 'MESSAGE_VIEW_PASEADORES';

export const ALL_SOLICITUDES = 'ALL_SOLICITUDES';
export const ACTIVO_SOLICITUDES = 'ACTIVO_SOLICITUDES';
export const LOADING_ALL_SOLICITUDES = 'LOADING_ALL_SOLICITUDES';
export const LOADING_ONE_SOLICITUDES = 'LOADING_ONE_SOLICITUDES';
export const MESSAGE_VIEW_SOLICITUDES = 'MESSAGE_VIEW_SOLICITUDES';

export const ALL_RAZAS = 'ALL_RAZAS';
export const ACTIVO_RAZAS = 'ACTIVO_RAZAS';
export const LOADING_ALL_RAZAS = 'LOADING_ALL_RAZAS';

export const ALL_PASEOS = 'ALL_PASEOS';
export const ACTIVO_PASEOS = 'ACTIVO_PASEOS';
export const LOADING_ALL_PASEOS = 'LOADING_ALL_PASEOS';

export const ALL_PEDIDOS = 'ALL_PEDIDOS';
export const ACTIVO_PEDIDOS = 'ACTIVO_PEDIDOS';
export const LOADING_ALL_PEDIDOS = 'LOADING_ALL_PEDIDOS';

export const ALL_CLIENTES = 'ALL_CLIENTES';
export const ACTIVO_CLIENTES = 'ACTIVO_CLIENTES';
export const LOADING_ALL_CLIENTES = 'LOADING_ALL_CLIENTES';

export const ALL_TARIFAS = 'ALL_TARIFAS';
export const ACTIVO_TARIFAS = 'ACTIVO_TARIFAS';
export const LOADING_ALL_TARIFAS = 'LOADING_ALL_TARIFAS';
