import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { Typography, Paper, Box, Grid, Alert } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { mdTheme } from "../../../components/template";
import CopyrightTemplate from "../../../components/template/Copyright";
import { useDispatch } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { useHistory } from "react-router-dom";
import { loginAction } from "../../../store/Actions";
import { REMEMBER_ME, EMAIL } from "../../../constants/ConfigConst";
import { SET_LOGOUT } from "../../../store/Types";
import { BLANCO_COLOR, ROJO_COLOR } from "../../../constants/ColorsConst";
import * as CONST from "../../usuarios/UsuariosConst";
const LoginModule = () => {
  const dispatch = useDispatch();
  let history = useHistory();

  const [dataLogin, setDataLogin] = React.useState({
    [CONST.USER]: "",
    [CONST.PASSWORD]: "",
    isRemember: false,
  });
  const [userError, setUserError] = React.useState(false);
  const [passwordError, setPasswordError] = React.useState(false);

  const [showPassword, setShowPassword] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [errorLogin, setErrorLogin] = React.useState(null);

  const _handleSetDataLogin = (prop, check) => (event) => {
    setDataLogin({
      ...dataLogin,
      [prop]: check ? event.target.checked : event.target.value,
    });
  };

  React.useEffect(() => {
    dispatch({
      type: SET_LOGOUT,
    });
    const userLocal = localStorage.getItem(EMAIL);
    const rememberMeLocal = localStorage.getItem(REMEMBER_ME);
    const x = {
      [CONST.USER]: userLocal,
      isRemember: rememberMeLocal === "1",
    };
    setDataLogin({ ...dataLogin, ...x });
  }, []);

  const _handleSubmit = async (event) => {
    event.preventDefault();

    let validUser = dataLogin.user === "";
    let validPaswword = dataLogin.password === "";

    setUserError(validUser);
    setPasswordError(validPaswword);

    if (!validUser && !validPaswword) {
      setLoading(true);
      const res = await loginAction(dataLogin, { dispatch, history });
      setErrorLogin(res);
      setLoading(false);
    }
  };

  const [num, setNum] = React.useState(Math.floor(Math.random() * 5) + 1);

  return (
    <ThemeProvider theme={mdTheme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={8}
          sx={{
            backgroundImage: `url(./img/${num}.jpg)`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid
          item
          xs={12}
          sm={8}
          md={4}
          component={Paper}
          elevation={10}
          square
        >
          <Box
            sx={{
              mt: 3,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "80%",
            }}
            autoComplete="off"
          >
            <img alt="Dogout" src="img/logo2.png" width="100" />
            <Typography
              sx={{ mt: 1, mb: 3, fontWeight: 700 }}
              component="h1"
              variant="h3"
            >
              DogOut
            </Typography>
            <Typography sx={{ mt: 2, mb: 2 }} component="h1" variant="h5">
              Inicio de Sesión
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={_handleSubmit}
              sx={{
                mt: 1,
              }}
            >
              {!errorLogin ? (
                <></>
              ) : (
                <Alert
                  severity="error"
                  sx={{ mb: 2 }}
                  onClick={() => {
                    setErrorLogin(null);
                  }}
                >
                  {errorLogin}
                </Alert>
              )}
              {CONST.fieldsUsuario(CONST.USER, {
                value: dataLogin.user,
                onChange: _handleSetDataLogin(CONST.USER, false),
                error: userError,
              })}
              {CONST.fieldsUsuario(CONST.PASSWORD, {
                value: dataLogin.password,
                onChange: _handleSetDataLogin(CONST.PASSWORD, false),
                error: passwordError,
                showPassword,
                onClick: () => {
                  setShowPassword(!showPassword);
                },
              })}
              <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                loading={loading}
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: ROJO_COLOR,
                  color: BLANCO_COLOR,
                  "&:hover": {
                    backgroundColor: ROJO_COLOR,
                  },
                }}
              >
                ENTRAR
              </LoadingButton>
              {/*
                            <Grid container>
                                <Grid item xs>
                                    <Button
                                        sx={{
                                            mt: 0
                                        }}
                                        onClick={() => {
                                            history.push('/recoverPassword')
                                        }}
                                    >
                                        Recuperar contraseña
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Link href="#" variant="body2">
                                        {"Don't have an account? Sign Up"}
                                    </Link>
                                </Grid> 
                            </Grid>
                            */}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              flexDirection: "column",
              height: "100px",
              width: "100%",
              pr: 2,
            }}
          >
            <CopyrightTemplate />
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default LoginModule;
