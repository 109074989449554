import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import * as React from 'react';
// import ButtonFile from "../../../components/ButtonFile";
// import BotonNuevoComponent from "../../entidades/components/BotonNuevo";

const FormUsuarios = ({ activo, setOpenModalForm, code }) => {
  // const dispatch = Redux.useDispatch();
  // const authStore = Redux.useSelector((state) => state.auth);
  // const usuariosStore = Redux.useSelector((state) => state.usuarios);

  // const initState = {
  //     [CONST.EMAIL]: "",
  //     [CONST.PASSWORD]: "",
  //     [CONST.PASSWORD_CONFIRMATION]: "",
  //     [CONST.DOCUMENT_TYPE_ID]: "1",
  //     // [CONST.IDENTIFICATION_NUMBER]: "",
  //     [CONST.FIRST_NAME]: "",
  //     [CONST.FIRST_LAST_NAME]: "",
  //     [CONST.BIRTHDAY]: null,
  //     [CONST.GENDER]: "male",
  //     [CONST.PHONE]: "",
  //     [CONST.STATUS_ID]: 1,
  // }

  //     const [user, setUser] = React.useState({
  //         ...initState,
  //         [CONST.IDENTIFICATION_NUMBER]: "",
  //     });
  //     const [errorPass, setErrorPass] = React.useState(false);
  //     const [dataError, setDataError] = React.useState({
  //         [CONST.EMAIL]: false,
  //         [CONST.IDENTIFICATION_NUMBER]: false,
  //         [CONST.FIRST_NAME]: false,
  //         [CONST.FIRST_LAST_NAME]: false,
  //         [CONST.PHONE]: false,
  //     });
  //     const _handleSetDataFieldUser = (key, value) =>
  //         setUser({ ...user, [key]: value });

  //     const _validDataForm = () => {
  //         const errorP = activo === null && user.password === "";
  //         setErrorPass(errorP);

  //         let r = {};
  //         let va = [];
  //         Object.keys(dataError).map((key) => {
  //             const val = user[key];
  //             const valid = val === null || val === "" || val === 0;
  //             r[key] = valid;
  //             va.push(valid);
  //         });
  //         setDataError(r);
  //         return !va.includes(true);
  //     };

  //     const [loadingSave, setLoadingSave] = React.useState(false);
  //     const handleSubmit = async () => {
  //         if (user.identification_number.length < 6) {
  //             setDataError(CONST.PASSWORD, true);
  //         }

  //         const valid = await _validDataForm();

  //         if (valid) {
  //             const data = {
  //                 [CONST.USER]: {
  //                     [CONST.EMAIL]: user.email,
  //                     [CONST.PASSWORD]: user.password,
  //                     [CONST.PASSWORD_CONFIRMATION]: user.password_confirmation,
  //                     [CONST.STATUS_ID]: user.status_id,
  //                 },
  //                 [CONST.PROFILE]: {
  //                     [CONST.DOCUMENT_TYPE_ID]: parseInt(user.document_type_id),
  //                     [CONST.IDENTIFICATION_NUMBER]: user.identification_number,
  //                     [CONST.FIRST_NAME]: user.first_name,
  //                     [CONST.FIRST_LAST_NAME]: user.first_last_name,
  //                     [CONST.BIRTHDAY]: user.birthday,
  //                     [CONST.PHONE]: user.phone,
  //                     [CONST.GENDER]: user.gender,
  //                 },
  //                 [CONST.USER_ID]: user.user_id,
  //                 [ADMIN]: true,
  //                 [CONST.CODE_ID]: user.code_id,
  //                 [CONST.USER_TYPE]: user.user_type,
  //             };
  //             setLoadingSave(true);
  //             let res
  //             if (usuariosStore.userNumber && usuariosStore.activo === null) {
  //                 const dUser = {
  //                     "code_id": user.code_id,
  //                     "user_type": user.user_type,
  //                     "user_ids": [
  //                         usuariosStore.userNumber.user.id
  //                     ]
  //                 }
  //                 res = await saveUsuarioCodigoAction(dispatch, dUser, usuariosStore.isAllDogout ? null : entidadesStore.activo.id)
  //             } else {
  //                 res = await saveUsuariosAction(
  //                     dispatch,
  //                     data, {
  //                     idEnt: entidadesStore.activo.id,
  //                     id: activo ? activo.id : null,
  //                 });
  //             }
  //             if (res) setOpenModalForm(false);
  //             setLoadingSave(false);
  //         }
  //     };

  //     React.useEffect(() => {
  //         if (activo === null) return;
  //         // if (activo.informacion.imgFoto) setImgFoto(activo.informacion.imgFoto)
  //         const code = activo.attributes.user_code.length > 0 ? activo.attributes.user_code : null
  //         let d = {
  //             [CONST.USER_ID]: activo.id,
  //             [CONST.EMAIL]: activo.attributes.user.email,
  //             [CONST.PASSWORD]: "",
  //             [CONST.PASSWORD_CONFIRMATION]: "",
  //             [CONST.DOCUMENT_TYPE_ID]: activo.attributes.document_type.id,
  //             [CONST.IDENTIFICATION_NUMBER]:
  //                 activo.attributes.profile.identification_number,
  //             [CONST.FIRST_NAME]: activo.attributes.profile.first_name,
  //             [CONST.FIRST_LAST_NAME]: activo.attributes.profile.first_last_name,
  //             [CONST.BIRTHDAY]: activo.attributes.profile.birthday,
  //             [CONST.GENDER]: activo.attributes.profile.gender,
  //             [CONST.PHONE]: activo.attributes.profile.phone,
  //             [CONST.STATUS_ID]: code ? code[0].status ? code[0].status.id : code[0].status_id : 1,
  //             [CONST.CODE_ID]: code ? code[0].code_id.toString() : null,
  //             [CONST.USER_TYPE]: code ? code[0].user_type : "owner",
  //             [CONST.CODE]: code ? code[0].code.code : null,
  //         };
  //         setTimeout(() => {
  //             setUser(d);
  //         }, 300);
  //     }, [activo]);

  //     React.useEffect(() => {
  //         if (activo) return;
  //         _handleSetDataFieldUser(CONST.PASSWORD, user.identification_number);
  //     }, [user.identification_number]);

  //     React.useEffect(() => {
  //         _handleSetDataFieldUser(CONST.PASSWORD_CONFIRMATION, user.password);
  //     }, [user.password]);

  //     const [dataCodes, setDataCodes] = React.useState([]);
  //     var delayTimer;
  //     function doSearch(text) {
  //         clearTimeout(delayTimer);
  //         setLoadingDataCode(true);
  //         delayTimer = setTimeout(async () => {
  //             setLoadingDataCode(false);
  //         }, 1000); // Will do the ajax stuff after 1000 ms, or 1 s
  //     }

  //     React.useEffect(() => {
  //         setDataCodes([])
  //         if (codigosStore.filtros.length > 0) {
  //             codigosStore.filtros.map((code) => {
  //                 setDataCodes(old => [...old, {
  //                     [CONST.ID]: code.id,
  //                     [CONST.CODE]: code.attributes.code,
  //                 }])
  //             })
  //         }
  //     }, [codigosStore.filtros]);

  //     const [loadingDataCode, setLoadingDataCode] = React.useState(false);
  //     const selectCode =
  //         <Autocomplete
  //             freeSolo
  //             id={`idpassow`}
  //             disableClearable
  //             options={dataCodes.map((option) => option.code)}
  //             size="small"
  //             loading={loadingDataCode}
  //             required
  //             autoComplete='off'
  //             value={user.code}
  //             onChange={(event, newValue) => {
  //                 const item = codigosStore.filtros.find(a => a.attributes.code === newValue)
  //                 _handleSetDataFieldUser(CONST.CODE_ID, item.id)
  //             }}
  //             renderInput={(params) => (
  //                 <TextField
  //                     {...params}
  //                     onChange={(e) => doSearch(e.target.value)}
  //                     label={`Buscar y asignar ${entidadesStore.typeEntity === 'Residencia' ? " una residencia" : entidadesStore.typeEntity === 'Club' ? "una acción" : "un código"}`}
  //                     InputProps={{
  //                         ...params.InputProps,
  //                         type: "search",
  //                         endAdornment: (
  //                             <React.Fragment>
  //                                 {loadingDataCode ? (
  //                                     <CircularProgress color="inherit" size={20} />
  //                                 ) : null}
  //                                 {params.InputProps.endAdornment}
  //                             </React.Fragment>
  //                         ),
  //                     }}
  //                 />
  //             )}
  //         />

  //     const [disabledInputsUser, setDisabledInputsUser] = React.useState(false);
  //     const [loadingDataUser, setLoadingDataUser] = React.useState(false);
  //     React.useEffect(() => {

  //         if (usuariosStore.activo) return

  //         let timer
  //         if (user.identification_number) {
  //             setLoadingDataUser(true)
  //             timer = setTimeout(async () => {
  //                 await getUsuariosAction(dispatch, { number: user.identification_number });
  //                 setLoadingDataUser(false)
  //             }, 1500);
  //         }
  //         if (timer) {
  //             return () => clearTimeout(timer);
  //         }
  //     }, [user.identification_number]);

  //     React.useEffect(() => {
  //         if (usuariosStore.activo) return

  //         if (usuariosStore.userNumber) {
  //             setDisabledInputsUser(true)
  //             const u = usuariosStore.userNumber.user
  //             const p = usuariosStore.userNumber.profile
  //             let d = {
  //                 ...initState,
  //                 [CONST.EMAIL]: u.email,
  //                 [CONST.DOCUMENT_TYPE_ID]: p.document_type_id,
  //                 [CONST.IDENTIFICATION_NUMBER]: p.identification_number,
  //                 [CONST.FIRST_NAME]: p.first_name,
  //                 [CONST.FIRST_LAST_NAME]: p.first_last_name,
  //                 [CONST.BIRTHDAY]: p.birthday,
  //                 [CONST.GENDER]: p.gender,
  //                 [CONST.PHONE]: p.phone,
  //             };
  //             setUser(d);
  //         } else {
  //             setUser(initState);
  //             setDisabledInputsUser(false)
  //         }
  //     }, [usuariosStore.userNumber]);

  //     const inputsForm = <>
  //         {
  //             usuariosStore.messageError ?
  //                 (<Grid item xs={12} md={12}><AlertMsgComponent
  //                     severity={usuariosStore.messageError.severity}
  //                     title={usuariosStore.messageError.title}
  //                 /></Grid>) : null
  //         }
  //         <Grid item xs={12} md={6}>
  //             <TiposDocumentosField
  //                 value={user.document_type_id}
  //                 onChange={(e, v) =>
  //                     _handleSetDataFieldUser(CONST.DOCUMENT_TYPE_ID, e.target.value)
  //                 }
  //                 error={dataError.document_type_id}
  //             />
  //         </Grid>
  //         <Grid item xs={12} md={6}>
  //             {/* {selectSearchUser} */}
  //             <TextField
  //                 fullWidth
  //                 size="small"
  //                 id={CONST.IDENTIFICATION_NUMBER}
  //                 required
  //                 label="Número de identificación"
  //                 value={user.identification_number}
  //                 onChange={(e) => {
  //                     let text = e.target.value
  //                     if (user.document_type_id === "1" && text.length === 9) return;
  //                     if (user.document_type_id !== "1" && text.length === 20) return;
  //                     _handleSetDataFieldUser(CONST.IDENTIFICATION_NUMBER, text)
  //                 }}
  //                 helperText={
  //                     dataError.identification_number ? validFielddHelper(1) : null
  //                 }
  //                 error={dataError.identification_number}
  //             />
  //         </Grid>
  //         <Grid item xs={12} md={6}>
  //             <TextField
  //                 fullWidth
  //                 size="small"
  //                 id={CONST.FIRST_NAME}
  //                 required
  //                 label="Nombre"
  //                 value={user.first_name}
  //                 disabled={disabledInputsUser}
  //                 onChange={(e) =>
  //                     _handleSetDataFieldUser(CONST.FIRST_NAME, e.target.value)
  //                 }
  //                 helperText={dataError.first_name ? validFielddHelper(1) : null}
  //                 error={dataError.first_name}
  //             />
  //         </Grid>
  //         <Grid item xs={12} md={6}>
  //             <TextField
  //                 fullWidth
  //                 size="small"
  //                 id={CONST.FIRST_LAST_NAME}
  //                 required
  //                 label="Apellido"
  //                 value={user.first_last_name}
  //                 disabled={disabledInputsUser}
  //                 onChange={(e) =>
  //                     _handleSetDataFieldUser(CONST.FIRST_LAST_NAME, e.target.value)
  //                 }
  //                 helperText={dataError.first_last_name ? validFielddHelper(1) : null}
  //                 error={dataError.first_last_name}
  //             />
  //         </Grid>
  //         <Grid item xs={12} md={6}>
  //             <GenerosField
  //                 value={user.gender}
  //                 onChange={(e, v) =>
  //                     _handleSetDataFieldUser(CONST.GENDER, e.target.value)
  //                 }
  //                 error={dataError.gender}
  //                 disabled={disabledInputsUser}
  //             />
  //         </Grid>
  //         <Grid item xs={12} md={6}>
  //             <FechaNacimientoField
  //                 v={user.birthday}
  //                 on={(e) =>
  //                     _handleSetDataFieldUser(
  //                         CONST.BIRTHDAY,
  //                         dayjs(e).format("YYYY-MM-DD")
  //                     )
  //                 }
  //                 dis={disabledInputsUser}
  //                 e={dataError.birthday}
  //             />
  //         </Grid>
  //         <Grid item xs={12} md={6}>
  //             <TextField
  //                 fullWidth
  //                 size="small"
  //                 id={CONST.PHONE}
  //                 required
  //                 label="Teléfono"
  //                 value={user.phone}
  //                 disabled={disabledInputsUser}
  //                 onChange={(e) =>
  //                     _handleSetDataFieldUser(CONST.PHONE, e.target.value)
  //                 }
  //                 helperText={dataError.phone ? validFielddHelper(1) : null}
  //                 error={dataError.phone}
  //             />
  //         </Grid>
  //         <Grid item xs={12} md={6}>
  //             <CorreoField
  //                 v={user.email}
  //                 on={(e) => _handleSetDataFieldUser(CONST.EMAIL, e.target.value)}
  //                 e={dataError.email}
  //                 dis={disabledInputsUser}
  //                 autoComplete='off'
  //             />
  //         </Grid>
  //         <Grid item xs={12} md={6}>
  //             <PasswordField
  //                 size="small"
  //                 showPassword
  //                 value={user.password}
  //                 onChange={(e) => {
  //                     _handleSetDataFieldUser(CONST.PASSWORD, e.target.value);
  //                 }}
  //                 mtl="-6px"
  //                 req={activo === null}
  //                 error={errorPass}
  //                 disabled={disabledInputsUser}
  //                 id='idpassword'
  //                 autoComplete='off'
  //             />
  //         </Grid>
  //         <Grid item xs={12} md={6}>
  //             <EstatusField
  //                 value={user.status_id}
  //                 onChange={(e) =>
  //                     _handleSetDataFieldUser(CONST.STATUS_ID, e.target.value)
  //                 }
  //                 error={dataError.status_id}
  //                 disabled={disabledInputsUser || activo === null}
  //             />
  //         </Grid>
  //     </>

  //     const inputCode = <>
  //         {
  //             code !== null && code !== undefined ? null : (
  //                 <Grid item xs={12} md={6}>
  //                     {selectCode}
  //                 </Grid>
  //             )
  //         }
  //         <Grid item xs={12} md={6}>
  //             <FormControl size="small" sx={{ width: "100%" }}>
  //                 <InputLabel id="selectUserType">Tipo de perfil</InputLabel>
  //                 <Select
  //                     labelId="selectUserType"
  //                     size="small"
  //                     value={user.user_type}
  //                     label="Tipo de perfil"
  //                     onChange={(e) =>
  //                         _handleSetDataFieldUser(CONST.USER_TYPE, e.target.value)
  //                     }
  //                     id={CONST.USER_TYPE}
  //                 >
  //                     <MenuItem value={OWNER}>Propietario</MenuItem>
  //                     <MenuItem value={SECURITY}>Seguridad</MenuItem>
  //                     <MenuItem value={FAMILY}>Familiar</MenuItem>
  //                     <MenuItem value={SERVICE}>Servicio</MenuItem>
  //                     <MenuItem value={NANNY}>Niñera</MenuItem>
  //                     <MenuItem value={DRIVER}>Chofer</MenuItem>
  //                 </Select>
  //             </FormControl>
  //         </Grid>
  //     </>

  //     const form = (
  //         <Box>
  //             <Grid container spacing={2}>
  //                 {inputsForm}
  //                 {inputCode}
  //             </Grid>
  //         </Box>
  //     );

  return (
    <Box component="form" sx={{ height: '450px' }}>
      <Grid container>
        {/* <Grid item xs={12} md={12}>
                    {loadingSave || loadingDataUser ? (
                        <Box sx={{ width: "100%" }}>
                            <LinearProgress />
                            {
                                loadingDataUser ?
                                    (<Typography variant="h6">
                                        Consultando número de identificación...
                                    </Typography>) : null
                            }
                            {
                                loadingSave ?
                                    (<Typography variant="h6">
                                        {activo
                                            ? "Actualizando el registro..."
                                            : "Guardando el registro..."}
                                    </Typography>) : null
                            }
                        </Box>
                    ) : null}
                </Grid> */}
        {/* <Grid item xs={12} md={3} >
                     <ButtonFile
                         id={CONST.IMG_FOTO}
                         onFile={(file) => setFileFoto(file)}
                         onDelete={() => setFileFoto(null)}
                         file={fileFoto}
                         text='Subir Foto'
                         sxc={{ marginTop: '5px' }}
                         name={imgFoto ? imgFoto.nombre : null}
                         src={imgFoto}
                     />
                 </Grid> */}
        <Grid item xs={12} md={12} sx={{ mt: 1 }}>
          {/* {form} */}
        </Grid>
        <Grid item md={12} sx={{ mt: 3 }} align="center">
          {/* <BotonNuevoComponent
                         click={handleSubmit}
                         text='GUARDAR'
                         disabled={loadingSave}
                         morado
                     /> */}
        </Grid>
      </Grid>
    </Box>
  );
};

FormUsuarios.propTypes = {
  setOpenModalForm: PropTypes.func,
};

export default FormUsuarios;
