import React from 'react';
import ContainerTemplate from '../../components/template/Container';
import * as ROUTES from '../../router/Routes';
import ClientesView from './ClientesView';

const ClientesModule = () => {
  return (
    <ContainerTemplate active={ROUTES.clientes} routes={[]}>
      <ClientesView />
    </ContainerTemplate>
  );
};

export default ClientesModule;
