import { SET_USER, SET_LOGOUT, ERROR_VIEW_LOGIN } from "../../../store/Types";

const initialState = {
  user: null,
  perfil: null,
};

export const LoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload.user,
        perfil: action.payload.user.perfil,
      };
    case ERROR_VIEW_LOGIN:
      return {
        ...state,
        errorView: action.payload,
      };

    case SET_LOGOUT:
      return initialState;

    default:
      return state;
  }
};
