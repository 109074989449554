import LogoutIcon from '@mui/icons-material/Logout';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import * as Redux from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ROJO_COLOR } from '../../../constants/ColorsConst';
import { logoutAction } from '../../../store/Actions';

const ButtonProfile = () => {
  const dispatch = Redux.useDispatch();
  let history = useHistory();

  const loginStore = Redux.useSelector((state) => state.login);

  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleModalClickOpen = () => {
    setOpen(true);
    setAnchorElUser(null);
  };

  const handleModalClose = () => setOpen(false);

  return (
    <div>
      <Box sx={{ flexGrow: 0 }}>
        <Tooltip title="Perfil">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
              }}
            >
              <Typography
                component="label"
                variant="body"
                color="inherit"
                noWrap
                sx={{ mr: 1 }}
              >
                {loginStore.user.informacion.pnombre}{' '}
                {loginStore.user.informacion.papellido}
              </Typography>
              <Typography
                component="label"
                variant="body"
                color="inherit"
                noWrap
                sx={{ mr: 1 }}
              >
                {loginStore.perfil}
              </Typography>
            </Box>
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
            </IconButton>
          </Box>
        </Tooltip>
        <Menu
          sx={{ mt: '40px' }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUser)}
          onClose={() => setAnchorElUser(null)}
        >
          {/* <MenuItem onClick={() => { }}>
                        <FaceIcon />
                        <Typography sx={{ ml: 1 }} >Mi Perfil</Typography>
                    </MenuItem> */}
          {/* <MenuItem onClick={() => {
                        history.push(ROUTES.ajustes.to)
                    }}>
                        {ROUTES.ajustes.icon}
                        <Typography sx={{ ml: 1 }} >{ROUTES.ajustes.title}</Typography>
                    </MenuItem> */}
          {/* <Divider sx={{ my: 0.5 }} /> */}
          <MenuItem onClick={handleModalClickOpen}>
            <LogoutIcon />
            <Typography sx={{ ml: 1 }}>Cerrar Sesión</Typography>
          </MenuItem>
        </Menu>
      </Box>
      <Dialog
        open={open}
        onClose={handleModalClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>CERRAR SESIÓN</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            ¿Seguro que quieres salir del sistema?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            onClick={() => {
              handleModalClose();
              logoutAction({ dispatch, history });
            }}
          >
            SALIR
          </Button>
          <Button sx={{ color: ROJO_COLOR }} onClick={handleModalClose}>
            CANCELAR
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ButtonProfile;
