export const DNI = 'dni';
export const PNOMBRE = 'pnombre';
export const SNOMBRE = 'snombre';
export const PAPELLIDO = 'papellido';
export const SAPELLIDO = 'sapellido';
export const FECHA_NACIMIENTO = 'fechaNacimiento';
export const PAIS = 'pais';
export const TELEFONO = 'telefono';
export const GENERO = 'genero';
export const USUARIO = 'usuario';
export const FOTO = 'foto';

export const NOMBRE = 'nombre';

export const fieldsUsuInformacion = (
  field,
  { value, onChange, error, required = true, onClick }
) => {
  switch (field) {
    case DNI:
      return;
  }
};
