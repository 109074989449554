import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import * as Redux from 'react-redux';
import * as Yup from 'yup';
import CountryCodeDropdown, {
  getCountry,
} from '../../../components/fields/CountryCode';
import { useNotification } from '../../../utils/Notification';
import BotonNuevoComponent from '../../dashboard/components/BotonNuevo';
import { getAllPaseosAction } from '../../paseos/PaseosAction';
import { saveTarifasAction } from '../TarifasAction';

const validationSchema = Yup.object({
  idPaseo: Yup.number().required('El paseo es requerido'),
  pais: Yup.object().required('El país es requerido'),
  simbolo: Yup.string().required('El símbolo es requerido'),
  costo: Yup.string().required('El costo es requerido'),
});

function toCapitalized(cadena) {
  return cadena.charAt(0).toUpperCase() + cadena.slice(1);
}

const FormTarifas = ({ activo, setModalForm }) => {
  const dispatch = Redux.useDispatch();
  const { notify } = useNotification();

  const paseosStore = Redux.useSelector((state) => state.paseos);

  React.useEffect(() => {
    const fetchData = async () => {
      await getAllPaseosAction(dispatch, {});
    };

    fetchData();
  }, []);

  const handleSubmit = async (values, { setSubmitting }) => {
    await saveTarifasAction({ dispatch, notify }, activo?.id, {
      idPaseo: values.idPaseo,
      paisPhone: `${values.pais.code}|${values.pais.phone}`,
      simbolo: values.simbolo,
      costo: values.costo,
    });
    setSubmitting(false);
    setModalForm(false);
  };

  return (
    <Formik
      initialValues={{
        idPaseo: activo?.paseo.id || '',
        simbolo: activo?.simbolo || '',
        costo: activo?.costo || '',
        pais: activo?.paisPhone ? getCountry(activo?.paisPhone) : null,
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <Field name="idPaseo">
                {({ field }) => (
                  <FormControl fullWidth size="small">
                    <InputLabel id="paseos-select-small-label">
                      Paseos *
                    </InputLabel>
                    <Select
                      labelId="paseos-select-small-label"
                      label="Paseo *"
                      {...field}
                    >
                      {paseosStore.all.map((paseo) => (
                        <MenuItem key={paseo.id} value={paseo.id}>
                          ({toCapitalized(paseo.modalidad)}) {paseo.nombre}{' '}
                          {paseo.recomendado ? '🌟' : ''}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Field>
            </Grid>
            <Grid item xs={12} md={12}>
              <Field name="pais">
                {({ field, form }) => (
                  <CountryCodeDropdown
                    value={field.value}
                    onChange={(event, newValue) => {
                      form.setFieldValue(field.name, newValue);
                    }}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                as={TextField}
                id="simbolo"
                name="simbolo"
                fullWidth
                size="small"
                required
                label="Símbolo de la moneda"
              />
              <ErrorMessage name="simbolo" component="div" />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                as={TextField}
                id="costo"
                name="costo"
                fullWidth
                size="small"
                required
                label="Costo del servicio"
              />
              <ErrorMessage name="costo" component="div" />
            </Grid>
            <Grid item xs={12} md={12} align="center">
              <BotonNuevoComponent
                type="submit"
                text="GUARDAR"
                verde
                disabled={isSubmitting}
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default FormTarifas;
