import axios from "axios";
import { baseUrl, token } from '../../services/Apis';

export const estatus = `${baseUrl}/statuses`;

export const getAllEstatusService = async () => {
    try {
        const res = await axios.get(estatus, {
            headers: await token({ isToken: true }),
        });
        return res
    } catch (e) {
        console.log(e);
    }
}
