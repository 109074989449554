import { grey } from '@mui/material/colors';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { baseUrl } from '../services/Apis';

export const dateformat = (
  date,
  { format = 'YYYY-MM-DD', utc = false, hhmm = false, full = false }
) => {
  return dayjs(date).format(
    hhmm ? 'HH:mm' : full ? 'YYYY-MM-DD HH:mm' : format
  );
};

export const textLabels = {
  body: {
    noMatch: 'No se encontrarón registros',
    toolTip: 'Ordenar',
    columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
  },
  pagination: {
    next: 'Pág, Siguiente',
    previous: 'Pág, Anterior',
    rowsPerPage: 'Filas por página:',
    displayRows: 'de',
  },
  toolbar: {
    search: 'Buscar',
    downloadCsv: 'Download CSV',
    print: 'Print',
    viewColumns: 'View Columns',
    filterTable: 'Filter Table',
  },
  filter: {
    all: 'TODOS',
    title: 'FILTROS',
    reset: 'REINICIAR',
  },
  viewColumns: {
    title: 'Show Columns',
    titleAria: 'Show/Hide Table Columns',
  },
  selectedRows: {
    text: 'row(s) selected',
    delete: 'Delete',
    deleteAria: 'Delete Selected Rows',
  },
};

export const stringToColor = (string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

export const stringAvatar = (name) => {
  return {
    sx: {
      bgcolor: grey[800],
      fontSize: '11pt',
      width: 30,
      height: 30,
    },
    children: `${name.split(' ')[0][0].toUpperCase()}${name
      .split(' ')[1][0]
      .toUpperCase()} `,
  };
};

export const urlImage = (img) => {
  if (img === undefined || img === null) return null;
  const url = img
    ? `${baseUrl}/archivos/${img.folder}/public/${img.nombre}`
    : '';
  return url;
};

export const tryCatchHelper = ({ e, notify }) => {
  let message =
    e?.response?.data?.message ||
    e?.message ||
    'Error al intentar, realizar el proceso';

  if (Array.isArray(message)) {
    message = message.join(', ');
  }

  if (notify) {
    notify(message, { error: true });
  } else {
    console.log(message);
  }
};

export const NameComponent = styled.p`
  cursor: pointer;
  font-weight: bold;
  color: black;
  margin: 0px 0px;
  text-transform: uppercase;

  &:hover {
    color: purple;
  }
`;
