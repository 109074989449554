import { GET_DASHBOARD } from '../../store/Types'
import { getDashboardService } from '../../services'

export const getDashboardAction = async (dispatch) => {
    const res = await getDashboardService();
    if (res.status === 200) {
        dispatch({
            type: GET_DASHBOARD,
            payload: res.data
        })
        return true
    }

    alert(res.message)
}